import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { getUserAccountDetailsAsync } from '../../../store/thunks/MasterThunk';
import carouselImage from "../../../assets/carouselHome.svg";
import ChatInput from '../../common/ChatInput';
import { setChatMessage, STATUS } from '../../../store/slices/ChatSlice';
import { Button } from '@headlessui/react';
import { ArrowRight } from 'lucide-react';
import { fetchAllTransactionsAsync } from '../../../store/thunks/TransactionThunk';
import { useNavigate } from 'react-router';
import GreetingHeader from '../../common/GreetingHeader';
import SpendingScreenWidget from './SpendingScreenWidget';
import { resetAccount } from '../../../store/slices/MasterSlice';
import SpendingAnalysisCard from './SpendingAnalysisCard';
import UpcomingBills from '../../common/UpcomingBills';

export interface HomeTabSectionProps {
    selectTab: (tabName: string) => void;
}

export function HomeTabSection({ selectTab }: HomeTabSectionProps) {
    const buttonLabels = [
        {
            title: 'My Monthly Spending',
            questionToAI: 'Show me a breakdown of my spending this month with key insights and trends',
        },
        {
            title: 'Find Overspending',
            questionToAI: 'Analyze my spending patterns and show me where I might be overspending',
        },
        {
            title: 'Top Spending Categories',
            questionToAI: 'What are my top spending categories from the last month?',
        }
        

    ];
    const dispatch = useDispatch<AppDispatch>();
    const spending_limit_adjusted = useSelector((state: RootState) => state.MasterStore).userAccount.spending_limit_adjusted;
    const loadingStatus = useSelector((state: RootState) => state.MasterStore).status;
    useEffect(() => {
        const fetchData = async () => {

            await dispatch(getUserAccountDetailsAsync());
        };
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchTransactionsData = async () => {
            await dispatch(fetchAllTransactionsAsync());
        };
        fetchTransactionsData();
    }, [dispatch]);



    const handleSend = (message: string) => {
        dispatch(setChatMessage(message));
        selectTab('Finley AI');
    };

    useEffect(() => {
        const fetchTransactionsData = async () => {
            await dispatch(fetchAllTransactionsAsync());
        };
        fetchTransactionsData();
    }, [dispatch]);

    const handleCategorySelected = () => {
        selectTab('Spending');
    };

    return (
        <div >
            {/* Sticky Header */}
            {/* <GreetingHeader /> */}


            {/* Main Content Section with Scroll */}
            <main className="flex-1 overflow-y-auto   px-3 ">
                <div className="py-3">
                    <ChatInput isLoading={false} send={handleSend} />
                </div>

                <div className="w-full overflow-x-auto hide-scrollbar">
                    <div className="flex space-x-1">
                        {buttonLabels.map((label, index) => (
                            <div key={index} className="flex-shrink-0">
                                <Button
                                    onClick={() => handleSend(label.questionToAI)}
                                    className="px-4 py-2 border-[0.5px] border-solid text-darkBlue bg-white rounded-full text3 border-neutral-300"
                                >
                                    {label.title}
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Conditional rendering */}
                {loadingStatus !== STATUS.SUCCESS ? (
                    <div className="text-center py-4">Loading...</div> // Show loading message when not success
                ) : (
                    spending_limit_adjusted ? (
                        <SpendingScreenWidget categorySelected={handleCategorySelected} />
                    ) : (
                        <SpendingAnalysisCard />
                    )
                )}
                <div className='mb-4'>
                <UpcomingBills />
                </div>
            </main>

        </div>
    );
}
