import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store'; // Ensure you import the correct RootState type
import { AccountInfo } from '../../types/CommonType';
import { CardFooter } from '../ui/Card';
import { BanknoteIcon } from 'lucide-react';
import { Button } from '@headlessui/react';
import minus from '../../assets/minus.svg';
import ActionModal from '../common/ActionModal';
import { deleteFinancialAccountItemsAsync, fetchAccountDetailAsync } from '../../store/thunks/TransactionThunk';

interface FilteredAccountsProps {
  filterTypes: string[]; // Array of types e.g. ['depository', 'checking']
  isOnborded: boolean;
}

const FilteredAccounts: React.FC<FilteredAccountsProps> = ({ filterTypes, isOnborded }) => {
  const { accounts } = useSelector((state: RootState) => state.TransactionStore);
  const [accountToDelete, setAccountToDelete] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  // Use filter to get accounts based on the provided filterTypes
  const filteredAccounts = (!filterTypes || filterTypes.length === 0)
    ? accounts // No filter or empty filter, return all accounts
    : accounts?.filter((account: AccountInfo) => filterTypes.includes(account.type)) || [];
  const handleDelete = async (itemId: string) => {
    // Set the account ID to be deleted and open the modal
    setAccountToDelete(itemId);
    setIsModalOpen(true);
  };
  const confirmDelete = async () => {
    if (accountToDelete !== null) {
      await dispatch(deleteFinancialAccountItemsAsync(accountToDelete));
      await dispatch(fetchAccountDetailAsync());
      setIsModalOpen(false);
    }
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {filteredAccounts && filteredAccounts.length > 0 && (
        <CardFooter>
          <div className="flex flex-row w-full">
            <div>
              {filteredAccounts.map((account: AccountInfo) => (
                <div key={account.id} className="flex items-center gap-3 self-stretch mt-3">
                  <div className="flex p-3 items-center gap-3 flex-1 self-stretch bg-neutral-100 rounded-lg">
                    {/* Logo Image */}
                    {account.institution?.logo_content ? (
                      <img
                        src={`data:image/png;base64,${account.institution.logo_content}`}
                        alt={`${account.institution.institution_name} logo`}
                        className="size-9"
                      />
                    ) : (
                      <BanknoteIcon className="text-customBlue size-9" />
                    )}
                    {/* Institution Name */}
                    <div className="flex flex-col items-start">
                      <div className="carouselSubTitle">
                        {account.official_name} <span className="intertextxs text-neutral-500">(... {account.mask})</span>

                      </div>

                    </div>
                  </div>
                  {/* check closing dive and remove condition*/}
                  {1 == 1 &&
                    <div>
                      <Button className="rounded-lg border border-neutral-200 bg-white p-2" onClick={() => handleDelete(account.item_id)}>
                        <img src={minus} alt="Delete" />
                      </Button>
                    </div>}
                </div>
              ))}
            </div>
            <ActionModal
              isOpen={isModalOpen}
              onClose={cancelDelete}
              onAction={confirmDelete}
              message="Are you sure you want to delete this account? This will remove all accounts from this institution."
              cancelText="Cancel"
              actionText="Delete"
            />
          </div>
        </CardFooter>
      )}
    </>
  );
};

export default FilteredAccounts;
