export interface Pagination {
  page: number,
  per_page: number,
  orgId: number

}
export interface Organization {
  id: number;
  name: string;
  displayName: string;
  contactName: string;
  contactEmail: string;
  parentOrgId: number | null;
  is_active: boolean;
  created_at: string;
  updated_at: null | string;
}

// Defining possible status values
export const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  IDLE: "idle",
  FAILED: "failed",
};

interface Institution {
  id: number;
  logo_content: string | null;
  institution_name: string;
  institution_id: string;
  url: string;
}

export interface AccountInfo {
  id: number;
  account_id: string;
  item_id: string;
  institution: Institution;
  persistent_account_id: string;
  name: string;
  official_name: string;
  mask: string;
  subtype: string;
  type: string;
  available_balance: number;
  current_balance: number;
  iso_currency_code: string;
  balance_limit: number | null;
  unofficial_currency_code: string | null;
  user_id: string;
  created_at: string;
  updated_at: string;
}
export interface StepProps {
  onNext: () => void;
}

export const MESSAGES = {
  ALREADY_ONBOARDED_USER_MESSAGE: "Your work email is already registered to a Finley account. Enter your password below to sign in.",
  UNAUTHORIZER_USER_MESSAGE: `<p> I couldn't find this email in our system. If you think this is a mistake, our team is here to help at <a href="mailto:help@getfinley.com" class=" underline">help@getfinley.com</a></p>`,
  PASSWORD_FORMAT_MESSAGE: "Create a password that'll keep your account safe:<br />- At least 12 characters<br />- Include both letters and numbers<br />- One uppercase letter<br />- One special character (!@#$%^&*)",
  OTP_VERIFICATION_FAILURE_MESSAGE: "Invalid one time code. Please wait 30 seconds and click to “Resend Code” to continue.",
  EMAIL_EMPTY: "Please enter a valid email address.",
  LOGIN_FAILED_MESSAGE: "Login failed. Please check your credentials.",
  // INVALID_EMAIL_MESSAGE:"Oops! That doesn't look like a valid email address. Please double-check and try again.",
  ACCOUNT_CREATION_FAILED: "Account creation failed. Please try again.",
  ACCOUNT_CREATION_INPROGRESS: "Account creation in progress."

};
export enum HttpStatus {
  Conflict = 409,
  Unauthorized = 401,
  Success = 200,
  NotFound = 404,
  BadRequest = 400,
  UnprocessableEntity = 422,
  InternalServerError = 500,
  Unknown = 0
}
const TermsContent = (
  <div className="text3 space-y-4">
    <p><strong>LAST UPDATED:</strong> March 7, 2024</p>

    <p>
      Welcome to the Questis website,{" "}
      <a className="text-blue600 hover:underline" href="https://www.questis.co">www.questis.co</a> (the “Website”).
    </p>

    <p>
      Your use of our Website is subject to the following Terms and Conditions (“Terms”) and our Privacy Policy which can be found here:<a className="text-blue600 hover:underline " href="https://www.questis.co/privacy-policy/"> Privacy Policy.</a> Please read these Terms carefully before using the Website. If You do not agree to these Terms, please do not use our Website.
    </p>

    <p>
      <strong>PLEASE BE ADVISED</strong> THAT THESE TERMS ARE A LEGAL AGREEMENT
      BETWEEN YOU AND QUESTIS, AND YOUR USE OF THIS WEBSITE IS GOVERNED BY THESE
      TERMS.
    </p>

    <h2>About Us</h2>
    <p>
      <strong>Questal, Inc.</strong> (“Questis,” “we,” “us,” or “our”) is a
      Delaware corporation with offices at 4 West Palisade Ave, Englewood, NJ.
      Should you have any questions about these Terms and Conditions or wish to
      contact us for any reason, please use the Contact Us section on our
      Website.
    </p>

    <h2> <strong>Privacy</strong></h2>
    <p>
      Our Privacy Policy also governs your visit to our Website
      and describes what information we collect from you, and how that
      information is used, shared, secured, and eventually deleted. You can
      review our Privacy Policy here:<a href="https://www.questis.co/privacy-policy/" className="text-blue600 hover:underline">Privacy Policy</a>.
    </p>

    <h2> <strong>Our Website’s Intended Audience</strong></h2>
    <p>
      Our Website is intended for and directed to residents of the United States
      and for persons over the age of 18. This Website is not intended for or
      marketed to anyone under the age of 18. You must be 18 years old or the
      age of majority in your jurisdiction of residence to use the services
      offered on our Website.
    </p>

    <h2><strong>Not Legal, Tax, or Investment Advice</strong></h2>
    <p>
      Questis does not provide insurance, tax, or investment advice. The Website
      and its contents are not intended for and should not be construed as legal
      or tax advice.
    </p>

    <p>
      The Website does not constitute a solicitation of an offer to buy or an
      offer to sell products, securities, stocks, bonds, or any other similar
      financial product.
    </p>

    <p>
      For information specific to your personal situation, consult with your
      attorney, tax advisor, or investment advisor. The Website, the contents,
      and Questis’ services are intended to provide you with general information
      and assistance with respect to financial education and goals. Our services
      may assist you in developing a plan to modify financial behaviors and
      reach your personal financial goals. We are not responsible for the
      consequences of any decisions or actions taken in reliance upon or as a
      result of the content made publicly available on the Website.
    </p>
    <h2><strong>INTELLECTUAL PROPERTY AND LIMITED LICENSE </strong> </h2>
    <p>All content available on the Website, including but not limited to text, graphics, logos, button icons, images, audio clips, data compilations and software, and the compilation and arrangement thereof (collectively, the “Content”) is the property of Questis, our affiliates, partners, licensors, or service providers, and is protected by United States and international copyright laws.</p>
    <p>The trademarks, logos, and service marks displayed on the Website (collectively, the “Trademarks”) are the registered and unregistered marks of Questis, our affiliates, partners, licensors or service providers, in the United States and other countries, and are protected by United States and international trademark laws.</p>
    <p> Except as required under applicable law, neither the Content or Trademarks nor any portion of the Website may be used, reproduced, duplicated, copied, sold, resold, accessed, modified, or otherwise exploited, in whole or in part, for any purpose without our prior written consent. This includes any automatic or bot scrapping of the data and content contained on our Website. Any form of automatic scrapping of our Website and the data contained herein, is a violation of these Terms.</p>
    <p> If You would like to use or post any of our data, Content or Trademarks, You can send us a request for permission detailing the sought after data, Content, or Trademark, and how it will be used, via email to <a href="mailto:support@myquestis.com" className="text-blue600 hover:underline"> support@questis.co.</a> </p>
    <p>With regards to content that You are viewing on our Website and Your overall use of our Website, we do provide You with a limited, royalty-free, non-exclusive, nontransferable, right and license to access our Website and You may view, download, or print materials from our Website for Your own personal and non-commercial use (provided all original copyright, trademark, and other notices are preserved in their original form) (“Limited License”). This Limited License does not include any rights not specifically stated herein. Your use of our Website does not grant, license, or transfer to You any ownership or other rights in our Website content (including without limitation the Content and Trademarks), and except as expressly provided, nothing herein or within the Website shall be construed as conferring on You or any other person any license under any of Questis’ or any third party’s intellectual property rights, including, without limitation, any right to download, display, reproduce, distribute, modify, edit, alter, offer for sale, or enhance any of the Website or its Content in any manner whatsoever.</p>
    <p> Any rights not expressly granted to You in these Terms are expressly reserved by Questis. For greater certainty, You agree that You will not take any action that is inconsistent with Questis’ exclusive ownership of this Website, nor Questis’ ownership of, or any third party’s ownership of, any Website content (including without limitation the Content and Trademarks).</p>
    <p>If You believe that any of Your copyrighted material has been copied and posted to this Website in a way that infringes on Your copyright, please see the DMCA – Copyright Complaints provision below for more information on how to contact us to remove the same.</p>

    <h2><strong>ELECTRONIC COMMUNICATIONS</strong> </h2>
    <p>The information communicated on the Website constitutes an electronic communication. When You communicate with us through the Website or via other forms of electronic media, such as e-mail, You are communicating with us electronically. You agree that we may communicate electronically, subject to local privacy and anti-spam laws, and that such communications, as well as notices, disclosures, agreements, and other communications that we provide to You electronically, are equivalent to communications in writing and shall have the same force and effect as if they were in writing and signed by the party sending the communication.</p>
    <h2><strong>MOBILE FEATURES</strong> </h2>
    <p>We may offer features and services that are available to You via Your mobile phone. These features and services may include, without limitation, the ability to browse our Website from Your mobile device, upload content to our Website, receive messages from our Website, download applications to Your mobile phone or access Website features (collectively, the "Mobile Features"). Standard messaging, data and other fees may be charged by Your carrier when You use the Mobile Features. Your carrier may prohibit or restrict certain Mobile Features and certain Mobile Features may be incompatible with Your carrier or mobile device. Contact Your carrier with questions regarding these issues. As applicable, instructions on how to opt-out of certain Mobile Features will be disclosed in connection with those Mobile Features. The instructions will typically require You to text a keyword (e.g., "STOP," "CANCEL," "END," "UNSUBSCRIBE," "QUIT," etc.) to the applicable shortcode for the Mobile Feature.</p>
    <h2><strong> LINKING/SCRAPPING</strong> </h2>
    <p>You may not link the Website to any other site without Questis’ prior written consent. If and where the Website provides a link(s) to any third party website, such website may not be under Questis’ control and accordingly Questis will have no responsibility for, and does not make any representations or warranties with respect to, such website or any content, products or services in connection therewith, and Questis does not endorse any of the same.  Questis does not consent to this Website being scrapping by bot, automated means, or any other means, and/or used in a data set or to train any automated systems, including but not limited to any artificial intelligence. </p>
    <h2><strong>YOUR OBLIGATIONS AND RESPONSIBILITIES</strong> </h2>
    <p> In the access or use of the Website, You shall comply with these Terms and the special warnings or instructions for access or use posted on the Website. You shall always act in accordance with the law and/or applicable custom, and in good faith. It is strictly prohibited to use or contact this Website to disrupt or damage the Website, its contents or its security measures or to harass Questis or its services or personnel. You may not use the Website for commercial purposes. No unsolicited email (spam), or advertising or promotional materials may be directed to or through this Website. You may not use this Website in order to transmit, distribute, store, or destroy (through viruses or any other computer code, files, or programs which might in any way interrupt, limit, or interfere with the Website or Questis’ business) material (a) in violation of any applicable law or regulation, (b) in a manner that will infringe the copyright, patent, trademark, trade secret or other intellectual property rights of others or violate the privacy or publicity or other personal rights of others, or (c) that is libelous, obscene, threatening, abusive, or hateful. You agree that You will abide by any third-party company policies and terms necessary in using our Website (such as a third-party publisher terms of use or Facebook, Twitter or YouTube terms of use). </p>
    <p>IF YOU DEFAULT NEGLIGENTLY OR WILLFULLY IN ANY OF THE OBLIGATIONS SET FORTH IN THESE TERMS AND CONDITIONS (INCLUDING OUR PRIVACY POLICY), YOU SHALL BE LIABLE FOR ALL THE LOSSES AND DAMAGES THAT THIS MAY CAUSE TO QUESTIS, OUR AFFILIATES, PARTNERS OR LICENSORS.</p>
    <h2><strong>CUSTOMER ACCOUNTS</strong> </h2>
    <p>If You choose to register as a Customer on our Website, You may do so here: Registration.  Please note that if You do, use of Your account will be governed by Questis’ Customer Terms, and we recommend that You follow our Customer Cybersecurity Guidelines in setting up Your account. Additionally, You are responsible for maintaining the confidentiality of Your account, username and password and for restricting access to Your computer. You also agree to follow our rules for the creation of a complex password and keep that password safe and secure. You agree to accept responsibility for all activities that occur under Your account, username and/or password. You agree to provide only accurate, truthful information.
    </p>
    <p>
      We reserve the right to refuse service and/or terminate Customer Accounts without prior notice if You violate these Terms or if we decide, in our sole discretion, that it would be in Questis’ best interest to do so.
    </p>
    <p>
      If You notice any problems or suspect that Your account has been compromised, please contact us at <a href="mailto:support@myquestis.com" className="text-blue600 hover:underline"> support@questis.co.</a> to report the same as soon as possible
    </p>
    <h2><strong>USER GENERATED CONTENT</strong> </h2>
    <p>Any unsolicited suggestions, ideas, inquiries, materials, feedback, or other information You provide us (“User Generated Content”) will be treated as non-proprietary and non-confidential and, by submitting such information, You hereby grant us a nonexclusive, royalty-free, perpetual, transferable, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, sell, assign, translate, create derivative works from, distribute and display such information in any form, media, or technology, whether now known or hereafter developed, alone or as part of other works.</p>
    <p>
      You also acknowledge that Your submission of User Generated Content may not be returned and we may use Your User Generated Content, and any ideas, concepts or know how contained therein, for any purpose including, without limitation, developing, manufacturing, distributing and marketing products.

    </p>
    <p>
      You agree that You will not provide to us any User Generated Content that is unlawful, harmful, tortious, defamatory, libelous, obscene, invasive of the privacy of another person, threatening, harassing, abusive, hateful, racist, infringing, pornographic, violent or otherwise objectionable or inappropriate as determined by Questis; that You will not post any User Generated Content that contains personal information about any other individual besides Yourself, violates the privacy/publicity of any other individual or entity, or anything that You are under a contractual obligation to keep private or confidential; that You will not impersonate any person or organization, including without limitation, the personnel of Questis, or misrepresent an affiliation with another person or organization; You will not post any User Generated Content that contains viruses, corrupted files, or any other similar software or programs that may adversely affect the operation of the Website, or feature of the Website. We have to right to refuse to post, reject, delete, reformat and edit all User Generated Content, in our sole discretion.

    </p>
    <p>
      Further, if You provide us with suggestions, product ideas, changes or improvements to existing products, or any other feedback or suggestions about our products and services, we may or may not review and consider the same, but we reserve the right to use the same freely without any restrictions, credit to You, or compensation to You. Additionally, we are under no obligation to keep any such suggestions, ideas, or feedback confidential.

    </p>
    <p>Questis reserves the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website. You hereby waive and hold harmless Questis and its affiliates, partners, licensors and service providers from any claims resulting from any action taken by Questis or any of the foregoing parties during, or as a consequence of, investigations by either Questis, such aforementioned parties, or law enforcement authorities. </p>
    <h2><strong>DMCA - COPYRIGHT COMPLAINTS</strong> </h2>
    <p>The Digital Millennium Copyright Act of 1998 (the "DMCA") provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. It is our policy, in appropriate circumstances and at our discretion, to disable and/or terminate the accounts of users who repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual property rights of others. If You believe that Your work has been copied and posted on the Website in a way that constitutes copyright infringement, please provide our copyright agent with the following information: an electronic or physical signature of the copyright owner or of the person authorized to act on behalf of the owner of the copyright interest; a description of the copyrighted work that You claim has been infringed; a description of where the material that You claim is infringing is located on the Website; Your address, telephone number, and e-mail address; a written statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; a statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or are authorized to act on the copyright owner's behalf. Any notification by a copyright owner or a person authorized to act on such copyright owner’s behalf that fails to comply with requirements of the DMCA shall not be considered sufficient notice and shall not be deemed to confer upon us actual knowledge of facts or circumstances from which infringing material or acts are evident. We can be reached for claims of copyright infringement can be reached by writing to Questis, Inc., 4 West Palisade Ave, Englewood, NJ 07631 or by emailing: <a href="mailto:support@myquestis.com" className="text-blue600 hover:underline"> support@questis.co.</a></p>
    <h2><strong>INDEMNIFICATION</strong> </h2>
    <p>You agree to indemnify, defend, and hold harmless Questis, its affiliates, partners, licensors and service providers, and their respective officers, directors, employees, agents, licensors, representatives, and third-party providers to the Website from and against all claims, losses, expenses, damages, and costs, including reasonable attorneys’ fees, arising out of or relating to any violation of these Terms by You. We reserve the right to assume, at our sole expense, the exclusive defense and control of any matter subject to indemnification by You, in which event You will fully cooperate with us in asserting any available defenses.</p>
    <p>This provision does not require You to indemnify us for any unconscionable commercial practice by such party or for such party’s fraud, deception, false promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the Website.</p>
    <p>This Indemnification section does not apply to New Jersey residents.</p>
    <h2><strong>DISCLAIMER OF WARRANTIES</strong> </h2>
    <p>QUESTIS MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, IN CONNECTION WITH THESE TERMS, OUR WEBSITE, AND THE SERVICES OFFERED ON OUR WEBSITE, INCLUDING ITS CONTENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM OR THROUGH OUR WEBSITE, INCLUDING ANY PRODUCTS OR SERVICES, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. </p>
    <p>THE WEBSITE IS NOT A SUBSTITUTE FOR SEEKING FINANCIAL ADVICE CONCERNING YOUR SPECIFIC CIRCUMSTANCES FROM A QUALIFIED ATTORNEY, ACCOUNTANT, INVESTMENT, OR OTHER PROFESSIONAL ADVISOR. </p>
    <p>TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, QUESTIS DISCLAIMS ANY AND ALL IMPLIED OR STATUTORY WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA OR OPERABILITY, NON-INFRINGEMENT, MERCHANTABILITY OF THE WEBSITE OR FITNESS FOR A PARTICULAR PURPOSE.</p>
    <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
    <h2><strong>LIMITATION OF LIABILITY</strong> </h2>
    <p>QUESTIS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) RELATED TO OUR WEBSITE REGARDLESS OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT, OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL OUR MAXIMUM AGGREGATE LIABILITY EXCEED ONE HUNDRED UNITED STATES DOLLARS ($100.00).</p>
    <p>NOTWITHSTANDING THE FOREGOING, THESE DISCLAIMERS AND LIMITATIONS OF LIABILITY DO NOT EXCLUDE ANY PRODUCT LIABILITY CLAIMS, STATUTORY CONSUMER RIGHTS, DAMAGES ASSOCIATED WITH PERSONAL INJURY OR RESULTING FROM COMPANY INTENTIONAL MISCONDUCT, RECKLESSNESS, FRAUD, OR GROSS NEGLIGENCE. THE TERMS IN THIS SECTION ARE VALID ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.</p>
    <p>This Limitation of Liability section does not apply to New Jersey residents.</p>
    <h2><strong>CHOICE OF LAW/FORUM SELECTION</strong> </h2>
    <p>These Terms shall be governed by the laws of New Jersey as if the Terms were a contract wholly entered into and wholly performed within New Jersey. You further expressly consent and agree to submit to the exclusive jurisdiction and venue of (i) a state court of competent jurisdiction located in Bergen County, New Jersey. </p>
    <h2><strong>SEVERABILITY</strong> </h2>
    <p>If any provision in these Terms is found to be invalid or unenforceable or contrary to applicable law, such provision shall be construed, limited, or altered, as necessary, to eliminate the invalidity or unenforceability or the conflict with applicable law, and all other provisions of these Terms shall remain in effect.</p>
    <h2><strong>GENERAL </strong> </h2>
    <p>We reserve the right, in our sole discretion, to change these Terms at any time by posting the changes on the Website and revising the Last Updated date at the top of these Terms. Any changes are effective immediately upon posting to our Website. </p>
    <p>Your continued use of the Website following any changes to these Terms constitutes acceptance of such changes. Accordingly, You are expected to check this page from time to time to ensure you are aware of any changes, as they are binding to you. If You do not agree with any of the changes You should stop using our Website.</p>
    <h2><strong>CONTACT US </strong> </h2>
    <p>If You have any questions about these terms or our Services, please contact us at <a href="mailto:support@myquestis.com" className="text-blue600 hover:underline"> support@questis.co.</a> </p>

  </div>
);


const PrivacyContent = (<div className="text3 space-y-4">
  <p><strong>LAST UPDATED:</strong> March 5, 2024</p>
  <h2><strong></strong> </h2>
  <p></p>

  <h2><strong>Introduction </strong> </h2>

  <p>This Privacy Policy (“Policy”) tells You how Questis, Inc. (“Questis”, “we,” “us,” or “our”) collects, uses, shares, secures, and eventually deletes, information collected from and about visitors to our Website, and from our Customers. We respect Your privacy and are committed to protecting the Personal Information that You may share with us.</p>
  <p></p>
  <p>You may print a copy of this Privacy Policy by clicking here.</p>
  <h2><strong>Scope of this Policy</strong> </h2>
  <p>This Policy covers both our online and offline data collection activities, including information that we collect through our Website, as well as our offline with our on 1-on-1 financial coaching. </p>

  <h2><strong>By continuing to visit and use our Website, You are agreeing to the terms of this Policy. If You do not agree, then please exit this Website and close Your browser.   </strong> </h2>
  <p>Additionally, if You sign up for our coaching or other individualized programs, in which You share Your Personal Information with us directly either offline or via virtual meeting, You are consenting to the terms of this Policy as well. <strong> If You do not consent to share Your Personal Information with us via these programs, then please do not participate in the same and do not share any of Your Personal Information with us.</strong></p>

  <h2><strong>Definitions </strong> </h2>
  <p>“Customer” means a natural person who signs up for our services.  </p>
  <p>“Personal Information” means any information relating to an identified or identifiable individual that is collected from our Customers and Users via our Website or offline at one of our in-person events or programs. Personal Information may include a Customer’s or a User’s: full name, physical address, email address, IP address, or other identifier such as from a Cookie.   </p>
  <p>Personal Information does not include any aggregate or anonymized data that may have been created from Personal Information but can no longer be used to identify, relate to or could reasonably be linked to a Customer or a User. </p>
  <p>“Sensitive Personal Information” means any information collected from our Customers and Users that reveals a racial or ethnic origin, political opinion, religious belief, philosophical belief or trade union membership. It also may include a natural person’s genetic and biometric data, financial information, and data concerning their health, sex life or sexual orientation. </p>
  <p>“User” means a natural person that visits and interacts with our Website.</p>
  <p>“You” or “Your” means a Customer or User. </p>
  <p>“Website” means <a href="https://www.questis.co/" className="text-blue600 hover:underline"> https://www.questis.co/.</a></p>

  <h2><strong>Questis’ Information Collection Practices Overview</strong> </h2>
  <p>Questis offers a range of services, both via our Website and offline and via virtual meeting via our coaching sessions.  It is our policy to only obtain the Personal Information that is necessary to provide our products and services to You and to limit the access to customer Personal Information to only those individuals who need it to serve our Customers’ and Users’ needs. </p>
  <p>Additionally, as we strive to provide the best service and experience, certain information may also be used to maintain and improve our customer service to You; be used to create and develop new products, offerings, and events; and otherwise support the provision of our services to our Customers. We have outlined all of the different ways that we share and use Your information in this Policy. </p>

  <h2><strong>Information that We Collect from You when You visit Our Website</strong> </h2>
  <p>We collect certain Personal Information from You in order to operate our Website effectively and provide You with the best experiences when You visit and use it.</p>

  <h2><strong>Information Provided by You</strong> </h2>
  <p>When You register for a Customer account online or sign up for a subscription with us, we will collect certain Personal Information about You such as Your name, address, e-mail address, date of birth or age range, telephone number, general geographic location (such as Your zip code or city and state), hobbies and interests, information about your job and employer, or other lifestyle information. We may also collect Your Sensitive Personal Information, such as Your gender, Your financial information (such as details of Your financial accounts, salary information, and tax information), and information about your financial goals. Please note that we utilize MX Technologies Inc. to collect, process, and store this information for You via our platform. </p>
  <p>You have the option to modify certain demographic information stored under Your account profile.</p>
  <p>We will also collect certain Personal Information about You if You sign up to receive our marketing communications or if You provide us with a review or feedback about our products, we may collect certain Personal Information from You along with that feedback and in certain cases Your Sensitive Personal Information as well too. </p>
  <p>If You are a Customer and make a purchase with us directly online, we also need to collect Your financial information, which is also considered Sensitive Personal Information. We would also need to collect any information that You use to make a purchase, such as Your credit card details (cardholder name, card number, expiration date, etc.) or other forms of payment (if such are made available). This also includes the billing name and address associated with Your form of payment. If You choose, You can have Your payment information stored as well (along with Your account history and other related preferences). Please note that we utilize a third-party payment processor to facilitate all online payments and thus when You make a payment and if You choose to store Your payment information on Your account, such information will be collected, processed, and stored by the Payment Processor not with us directly. Please see our Payment Processing section below for more information. </p>

  <h2><strong>Information collected on Our Website</strong> </h2>

  <p>
    <span> 1. <strong>Information collected automatically:</strong>   We may </span>
    <p className="pl-4">
      automatically collect information from You when You visit Our Website. This information may include Your IP address, location data, Your browser type and language, access times, the content of any undeleted cookies that Your browser previously accepted from us, referring or exit website address, internet service provider, date/time stamp, operating system, locale and language preferences, and system configuration information.
    </p>
  </p>
  <p>We use Google Analytics to collect information about Your use of Our Website as well.).  To disable Google Analytics, please download the browser add-on for the deactivation of Google Analytics provided by Google at https://tools.google.com/dlpage/gaoptout?hl=en, To learn more about privacy and Google Analytics, please see:  https://policies.google.com/technologies/partner-sites.</p>
  <p>We may also collect information from You automatically through any site or web application that we may develop and run on a third party social network such as Facebook. This could also include any site or application that we specifically design for use on a cell phone or other mobile device, such as a mobile-enabled site (i.e., WAP site) or mobile application (e.g., iPad/iPhone app). </p>

  <p>
    <span>
      1.<strong>
        Cookies:{' '}
      </strong>
      When You visit our Website We may
    </span>
    <p className="pl-4">
      assign Your device one or more cookies to facilitate access to our site and to personalize Your online experience. Through the use of a cookie, we also may automatically collect information about Your online activity on our site, such as the web pages You visit, the links You click, and the searches You conduct on our site. Most browsers automatically accept cookies, but You can usually modify Your browser setting to decline cookies.
    </p>
  </p>
  <p>A cookie is a small text file that is stored on a user’s computer for record keeping purposes. Cookies can be either session cookies or persistent cookies. A session cookie expires when You close Your browser and is used to make it easier for You to navigate our website. A persistent cookie remains on Your hard drive for an extended period of time. </p>
  <p>We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about Your use of Our site with Our social media, advertising and analytics partners, who may combine it with other information that You’ve provided to them or that they’ve collected from Your use of their services. </p>
  <p>When You first visit our site, we provide You with a choice to either accept or deny certain cookies. If You choose to decline cookies please note that some of the features and services of our Website may not function as well as intended. Even if You choose to accept cookies, You are always free to change Your mind and revoke that consent. </p>
  <p>Also, to opt-out from third-party cookies that are used for advertising purposes, You can do so on the NAI website at https://www.networkadvertising.org /managing/opt_out.asp.</p>
  <p>Technical computer information is not typically associated with Your personal contact information.</p>
  <p>1.<strong>Customer Surveys and Feedback Forms </strong></p>
  <p>We may ask You to voluntarily share with us  comments on and aspects of Your experience in using our services, including coaching services and our Website, via customer surveys online and feedback forms that we may e-mail to or solicit from You. We may also collect comments and suggestions about our services and Website, testimonials, or other feedback You send us about what You may have liked (or disliked) about Your experience in using our services. </p>



  <p>
    <span>
      1.<strong>
        Other technologies:{' '}
      </strong>
      We may use standard
    </span>
    <p className="pl-4">
      Internet technology, such as web beacons, pixels, and other similar technologies, to track Your use of our Website. We also may include web beacons in promotional e-mail messages or newsletters to determine whether messages have been opened and acted upon. The information we obtain in this manner enables us to customize the services we offer to Customers and Users of our Website, to deliver targeted advertisements, and to measure the overall effectiveness of our online advertising, content, programming, or other activities. Web beacons (also known as clear gifs, pixel tags or web bugs) are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of web users or to access cookies. Unlike cookies, which are stored on Your device, web beacons are embedded invisibly on the web pages (or in emails) and are about the size of the period at the end of this sentence. Web beacons may be used to deliver or communicate with cookies, to count visitors to certain pages, and to understand usage patterns. We also may receive an anonymous identification number if You come to our Website from an online advertisement displayed on a third-party website.
    </p>
  </p>

  <h2><strong>Information Collected from You from Your Employer or the Referring Organization</strong></h2>
  <p>When You receive access to our services from Your employer or an organization you are a member of, we need to collect certain Personal Information about You such as Your name and work e-mail address or other email address or Personal Information to enable You to claim Your membership via Your employer or the referring organization.  </p>
  <p>Please note that we do not share any Personal Information that our Customers provide directly to with their employers or the referring organization. We only receive Your eligibility information from such third-party, and will only share the fact that You have signed up for our services.</p>
  <h2><strong>Information Collected from You Over via Phone, Live Chat, and E-Mail</strong></h2>
  <h2><strong>Live Chat with Customer Service Team</strong></h2>
  <p>If You use the Live Chat feature on our Website, we will collect certain information from You such as Your name, email address, and information about why You are chatting with us. Some of that information may be considered Sensitive Personal Information as it may reveal Your gender and race. We only collect this information in order to communication with You, address why You are chatting with us, and ensure that You have a great customer experience. We may also record the live chat and/or keep a transcript of the same for our business purposes and as needed for any follow-ups You may have about the issue You contacted us about. If You do not consent to the recording of Your live chat conversation, please contact us via email at Privacy@questis.co. Additionally, we may use a bot or other automated tool to collect certain information from You or to provide You with certain information when You interact with our Live Chat feature on our Website. </p>
  <h2><strong>Call Center and Customer Service</strong></h2>
  <p>If You contact us over the phone in order to place an order, speak to our Customer Service department or send postal mail to our customer service department, we will collect certain Personal Information from You, such as Your name, home address, e-mail address, and phone number. We may also need to use Your Personal Information to sign You up for our services, and/or further contact You to respond to Your inquiry or request. </p>
  <h2><strong>Why We Collect Your Information</strong></h2>
  <p>We collect Your Personal Information and Sensitive Personal Information for various reasons, which are described below: </p>
  <ul className="list-disc pl-5">
    <li>
      <strong>
        To Service Our Website and/or App
      </strong>
    </li>
  </ul>
  <p>We use information collected to ensure that our Website and/or App are working as intended and for analytics purposes, to understand how our Website and/or App are operating and learn how they can be improved. We also use this information as needed for auditing purposes as well.</p>
  <h2><strong></strong></h2>
  <ul className="list-disc pl-5">
    <li>
      <strong>
        To Provide Our Products and Services to You and Facilitate any Customer Services Issues
      </strong>
    </li>
  </ul>
  <p>We use information collected to provide our services to you, and to follow up with You about Your satisfaction with the ordered products.</p>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        To Maintain Your Account
      </strong>
    </li>
  </ul>
  <p>We may use information collected to maintain Your account(s) with us, including administering any programs associated with such account(s). </p>
  <ul className="list-disc pl-5">
    <li>
      <strong>
        For Our Marketing Purposes
      </strong>
    </li>
  </ul>
  <p>If You have filled out a contact form with us, signed up to receive our alerts or newsletter, or have otherwise signed up to receive our direct communications, we may use this information to send You marketing communications, such as email communications, mobile messages (including text and/or push notifications), and postal mailings. These communications may inform You about new products, store events, special discounts and coupons, beauty tips, and other news and special offers. On occasion, these communications may also contain information or offers about third party products. In some instances, we may use technical information or precise geo-location information obtained from Your mobile device to send You messages directly to Your mobile device, including offers and coupons based on Your location. </p>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        To Evaluate and Improve our Customer Service

      </strong>
    </li>
  </ul>
  <p>We may use this information to ensure we are providing the highest level of customer service, and to identify any potential gaps or potential improvements to the same. Customer service may be provided through various forms of communication, such as email correspondence and call center support.</p>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        To Evaluate, Improve, and Customize Our Products and Services
      </strong>
    </li>
  </ul>
  <p>We may use information collected to  improve our products and services, tailor them to Your needs, and come up with new product ideas. </p>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        To Administer Contest and Promotions
      </strong>
    </li>
  </ul>
  <p>We may use information collected to administer a contest, giveaway, competition, or other similar marketing campaign or promotion. For more information about our contests and other promotions, please see the official rules or details that will be posted with each promotion.</p>


  <ul className="list-disc pl-5">
    <li>
      <strong>
        In Order to Respond from Requests from You
      </strong>
    </li>
  </ul>
  <p>If You have requested further information or in any other way requested that we contact You, we will use Your Personal Information to respond to such a request. </p>


  <ul className="list-disc pl-5">
    <li>
      <strong>
        To Provide a Personalized and Convenient Website Experience for You
      </strong>
    </li>
  </ul>
  <p>We may use information collected to personalize Your experience and save You time when You visit our Website. For example, we might remember Your login ID or username so You can quickly login the next time You visit our site. Based on this type of information, we might also show You specific Questis content or offers that are more relevant to Your interests.</p>


  <ul className="list-disc pl-5">
    <li>
      <strong>
        For Our Other General Purposes
      </strong>
    </li>
  </ul>
  <p>We may use information collected other general business purposes, such as to maintain the day-to-day operation and security of our Website and/or App, and to conduct internal marketing and demographic studies. </p>


  <ul className="list-disc pl-5">
    <li>
      <strong>
        For Our Legal Purposes
      </strong>
    </li>
  </ul>
  <p>We also collect information in order to comply with certain legal rules and regulations that we are subject to.</p>
  <h2><strong>How Long do We Store and Maintain Your Personal Information?</strong></h2>
  <p>We only store and maintain Your Personal Information for as long as is necessary to fulfill the purpose for which it was collected. For example, if You sign up to receive Our newsletters or to receive job alerts, We will store and maintain Your Personal Information to continue to send You these materials until You opt out of receiving such communications. With regards to Your Personal Information collected for our analytics purposes and used to help maintain and improve Our Website, we only store and maintain that information until it is aggregated and used to create a new anonymous data set that no longer contains Your Personal Information, and then delete the underlying Personal Information once that new data set has been created. If You are one of our valued Customers and have created an account with us, we store Your Information as long as Your account remains active, and then delete it when You delete the account, with the exception of any information that we are legally required to maintain longer. If You are a Customer and You do not have an account, we only store Your information as long as needed to support Your purchase and relationship with us. When we no longer have a legal need, or You close or delete Your account with us, or otherwise ask us to delete Your account, we will do so securely. </p>


  <h2><strong>We Do Not Sell Your Personal Information </strong></h2>
  <p>Questis does not sell Your Personal Information that it has collected via Our Website to any third parties. We do not sell Your Sensitive Personal Information to any third parties and only use Your Sensitive Personal Information to provide our products and services to You. </p>
  <h2><strong>Who We Share Your Personal Information With</strong></h2>
  <p>Generally, we share Your Personal Information as necessary to provide the services or products You request, including sharing information with certain third parties, such as service providers. We also may share Your Personal Information when required to by law; to protect Your rights and safety, and with Your consent.</p>
  <p>Specifically, we may share Your Personal Information with third parties, including: </p>


  <div>


    <p>
      <span>
        1.<strong>
          Authorized service providers: {' '}
        </strong>
        We may share Your
      </span>
      <p className="pl-4">
        Personal Information with our authorized service providers that perform certain services on our behalf. These services may include relationship management software; website hosting; evaluators, contractors and auditors; email marketing providers; customer service providers; providers that perform business and operations analysis for us; and providers that support and maintain our Website, including providing analytics services for us.
      </p>
    </p>



    <p>
      <span>
        2.<strong>
          Payment Processors:{' '}
        </strong>
        We need to share Your
      </span>
      <p className="pl-4">
        Personal Information and Your Sensitive Personal Information with our third-party payment processor in order to complete Your purchases on-line. You may also be able to store Your payment information with these providers via Your profile with us. We do not store any of Your financial information directly and any such storage or remembering of the same will be facilitated by our third-party payment processor. Additionally, we utilize Stripe as our Payment Processor. Stripe collects, processes, and stores Your payment information directly for us. You can learn more about Stripe’s privacy practices here:<a href="https://stripe.com/privacy" className="text-blue600 hover:underline"> https://stripe.com/privacy. </a>
      </p>
    </p>



    <p>
      <span>
        3.<strong>
          For a Legal Reason:{' '}
        </strong>
        We may have to share Your
      </span>
      <p className="pl-4">
        Personal Information in response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us.
      </p>
    </p>



    <p>
      <span>
        4.<strong>
          To Prevent Fraud or Illegal Conduct:{' '}
        </strong>
        We may
      </span>
      <p className="pl-4">
        have to share Your Personal Information in situations where we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property, or safety of our company, our consumers, our employees, or others; or to enforce our Terms of Service or other agreements or policies.
      </p>
    </p>



    <p>
      <span>
        5.<strong>
          For Corporate Reasons:{' '}
        </strong>
        We may also share Your
      </span>
      <p className="pl-4">
        Personal Information as part of a sale, merger, or change in control or ownership of Questis, or in the unlikely event of bankruptcy, or in preparation for any of these events. If such an event should happen, the new entity would have the right to continue to use Your information, but only in the manner that is set out in this Policy, unless You otherwise agree.
      </p>
    </p>


  </div>
  <p>
    <span>
      1.<strong>
        With Your Consent:{''}
      </strong>
      To other third parties that
    </span>
    <p className="pl-4">
      You provide consent to share Your information with or that You have requested that we share Your information with.
    </p>
  </p>

  <h2><strong>How do we Secure and Protect Your Personal Information</strong></h2>
  <p>Questis maintains a comprehensive Information Security Program that employs commercially available physical and IT securitization tools, including but not limited to: </p>
  <div>
    <p>1. Commercially available and sufficient firewalls; </p>
    <p>2. Segmented Data Storage; </p>
    <p>3. Encryption; </p>
    <p>4 .Multi-factor authentication; and </p>
    <p>5. Policies providing for least privileged access to data across our organization.</p>

  </div>

  <p>Unfortunately, however, there is no way to completely eliminate the risk of a data security incident. If we do suffer one that affects any of Your Personal Information, we will report the same to You as per applicable data breach notification laws. </p>
  <p>Unfortunately, however, there is no way to completely eliminate the risk of a data security incident. If we do suffer one that affects any of Your Personal Information, we will report the same to You as per applicable data breach notification laws. </p>


  <h2><strong>Children’s Personal Information</strong></h2>
  <p>Our website and products are not made for, marketed to or directed at children under the age of 16 years of age. We do not sell the Personal Information of persons under 16 years of age. In the event that we learn that the Personal Information of a person under 16 years of age has been collected, we will delete it immediately from our system. </p>

  <h2><strong>California Shine the Light Law </strong></h2>
  <p>Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to request from a business, with whom the California resident has an established business relationship, certain information with respect to the types of personal information the business shares with third parties for direct marketing purposes by such third party and the identities of the third parties with whom the business has shared such information during the immediately preceding calendar year. However, we do not sell, rent, or otherwise share Your data to any third party in exchange for monetary or other valuable consideration under any circumstances. If You are a California resident and want a copy of this notice, please submit an email request to Privacy@Questis.co. In Your request, please specify that You want a “California Privacy Rights Notice.” Please allow 30 days for a response.

  </p>

  <h2><strong>Do Not Track (“DNT”) Settings</strong></h2>

  <p>DNT is a setting on some web browsers or mobile devices that can be turned on to instruct websites to disable tracking of Your web browsing activities. DNT is not currently widely adopted  and does not have clear standards or guidelines as to how websites are to interpret it. Additionally, its signals can affect the functioning of certain websites. As such, we do not generally respond to these signals. </p>
  <h2><strong>Third-Party Tracking on Our Website </strong></h2>
  <p>Third-parties may be using Cookies or other technologies to collect Your personal information as You navigate through the internet including from Our Website. As described above, You may decline these third-party Cookies by changing Your browser settings. </p>

  <h2><strong>Third Party Applications and Sites

  </strong></h2>
  <p>Our Website may provide links to, or features from, other third party sites (such as third party social networks) that we do not own or control. When You visit these third-party sites and decide to share Your Personal Information with them, You are assuming the risk of doing so. Questis is not responsible for nor assumes any liability for the privacy practices of websites operated by any third parties, including those that may be linked to our Website, and including the content of those sites. You should check the applicable privacy notice and/or policy of the third party website or application to understand how it collects and manages any information they collect from You.

  </p>
  <p>
    As noted in our Terms of Service, Questis does not endorse or control any other websites or applications besides Our Website, nor does the act of providing a link to a third party website or application constitute an endorsement, promotion or approval of such website or application.
  </p>

  <h2><strong>Nevada Residents Privacy Rights</strong></h2>
  <p>We do not sell, rent, or otherwise share Your data to any third party in exchange for monetary or other valuable consideration under any circumstances unless You specifically consent to such disclosure. Pursuant to Section 603A of the Nevada Revised Statutes, residents of Nevada may, at any time, submit a request to an operator of a website in Nevada directing the operator not to make any sale of any personal information the operator has collected or will collect about the consumer. If You are a Nevada resident and want to opt-out of the sale of any personal information, please submit an email request to privacy@orveonglobal.com. In Your request, please specify that You want to “Opt-Out of Sale of Personal Information in Nevada.” Please allow 60 days for a response.</p>

  <h2><strong>How to Opt Out </strong></h2>
  <p>You can always opt-out from receiving marketing communications by following the unsubscribe instructions provided in each communication. If You have an online account with us, You may be given the option to change some of Your communication preferences under the relevant “My Account” section of our site or by calling Customer Service. Please note that even if You opt-out from receiving marketing communications, You may still receive other communications from us, such as order confirmations, notifications about account activities (e.g., account confirmations, password changes, website community postings, etc.), and other important announcements (e.g., product recalls, privacy policy changes, etc.).</p>
  <p>You can also Opt Out of our Marketing Communications and otherwise exercise Your privacy rights by emailing us at: Privacy@questis.co.</p>
  <h2><strong>Changes to this Policy </strong></h2>
  <p>Any changes to the Policy will be posted directly on our Website and reflected with a new Effective Date at the top of this Policy. We encourage You to review the Policy each time You visit our Website to see if it has been updated since Your last visit.</p>

  <h2><strong>Contact Us</strong></h2>
  <p>If you have any questions or comments about this Policy, the ways in which we collect and use your Personal Information or your choices and rights regarding such collection and use, please do not hesitate to contact us at:

  </p>
  <p>Email: <a href="mailto:support@myquestis.com" className="text-blue600 hover:underline"> support@questis.co.</a></p>
  <p>Phone: 855-843-5233</p>
  <p>Mail: Questis, Inc., 4 W Palisade Ave #1210, Englewood, NJ 07631</p>


  <h2><strong>Last updated on: February 1, 2020</strong></h2>
  <p>This document describes how we treat personally identifiable information when you use Questis, Inc.’s services as described below.</p>

  <h2><strong>What is Questis?</strong></h2>
  <p>Questis, Inc. (“Questis”) operates an online digital advice delivery platform on https://go.myquestis.com. The Questis service and all content available thereon are collectively referred to herein as the “Site”. This Privacy Policy describes how Questis treats personally identifiable information when you use the Site, including information you provide to us as a user (“User”).</p>

  <h2><strong>How We Collect Personally Identifiable Information (or “PII”)</strong></h2>
  <div>
    <p>
      <span>
        1. You become a User when you access the Site,{''}
      </span>
      <p className="pl-4">
        pursuant to Questis’s Terms of Use. Except as required by law or requested by regulatory authorities, Questis agrees to maintain your non-public PII in strict confidence.
      </p>
    </p>

    <p>
      <span>
        2. Questis restricts access to non-public personally {''}
      </span>
      <p className="pl-4">
        identifiable information about Users to certain Questis employees who require that information in order to maintain and operate the Site. Questis may ask you for additional PII, such as your name or picture, in order to provide personalized services to you. Questis stores, processes, and maintains data related to you in order to provide its products and services through the Site. Questis’s uses for this data are described below.
      </p>
    </p>

    <p>
      <span>
        3. When you use the Site, Questis’s servers{''}
      </span>
      <p className="pl-4">
        automatically record certain information about your use of the Site. Similar to other web services, Questis records information such as browsing activity, data displayed or clicked on (such as UI elements, ads, and links), and other information (such as browser type, IP address, date and time of access, cookie ID, and referrer URL). Along with cookies, Questis may also use third-party tracking technology, such as Google Analytics, to record similar information regarding you and your activity on the Site.
      </p>
    </p>
    <p>
      <span>
        4. Questis is not directed to anyone under the age {''}
      </span>
      <p className="pl-4">
        of 18. If a parent or guardian becomes aware that his or her child under the age of 18 has provided us with personally identifiable information without his or her consent, he or she should contact us at <a href="mailto:support@myquestis.com" className="text-blue600 hover:underline">
          support@myquestis.com
        </a>. If we become aware that a child under the age of 18 has provided us with personally identifiable information, we will delete such information from our files.
      </p>
    </p>

  </div>


  <h2><strong>Our Use of Information
  </strong></h2>
  <div>
    <p>
      <span>
        1. Questis collects, maintains and processes both{''}
      </span>
      <p className="pl-4">
        PII and non-PII.  Examples of PII include name and email address.  Examples of non-PII include age and annual income.  Questis maintains and processes your Questis account and its contents to provide Questis’s products and services to you and to improve our services.
      </p>
    </p>

    <p>
      <span>
        2. Questis’s servers process the PII and non-PII {''}
      </span>
      <p className="pl-4">
        information you provide to Questis for various purposes, including but not limited to formatting and displaying your information, delivering related content, sharing community information, and other purposes relating to the Site.  Questis may also use non-PII for other business purposes at Questis’ sole discretion.
      </p>
    </p>

    <p>
      <span>
        3. Questis may send you information related to {''}
      </span>
      <p className="pl-4">
        Questis services. If you decide at any time that you no longer wish to receive marketing communications from us, please follow the “unsubscribe” instructions provided in the communications.
      </p>
    </p>
  </div>
  <p>
    <span>
      {''}
    </span>
    <p className="pl-4">
    </p>
  </p>

  <h2><strong>Information Sharing and Onward Transfer</strong></h2>
  <div>
    <p>
      <span>
        1. We do not sell, rent, or otherwise share your {''}
      </span>
      <p className="pl-4">
        personally identifiable information with any third parties except our affiliates, service providers and as permitted or required by law, such as when we reasonably believe it is necessary or appropriate to investigate, prevent, or take action regarding illegal activities, suspected fraud, frontrunning or scalping, situations involving potential threats to the physical safety of any person, or violations of our Terms of Use.
      </p>
    </p>

    <p>
      <span>
        2. You understand and agree that any personally{''}
      </span>
      <p className="pl-4">
        identifiable information that you provide through the site and explicitly agree to share publicly might be made publicly available at Questis’s discretion and that other users may use and reproduce such. We have no control over the use by other users of the information that you voluntarily disclose to Questis for this purpose or to the public in general.
      </p>
    </p>

    <p>
      <span>
        3. Questis maintains physical, electronic and {''}
      </span>
      <p className="pl-4">
        procedural safeguards that comply with applicable laws and regulations to guard Users’ non-public personally identifiable information.  Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure.  If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “Contact Us” section below.
      </p>
    </p>

    <p>
      <span>
        4. We may also transfer your information to {''}
      </span>
      <p className="pl-4">
        a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings).
      </p>
    </p>

    <p>
      <span>
        5. Questis may, in its sole discretion, also {''}
      </span>
      <p className="pl-4">
        share non-PII with third parties.
      </p>
    </p>

  </div>


  <h2><strong>Your Choices</strong></h2>
  <p>
    <span>
      1. You may request deletion of personally identifiable {''}
    </span>
    <p className="pl-4">
      information by emailing <a href="mailto:support@myquestis.com" className="text-blue600 hover:underline">
        support@myquestis.com
      </a> and providing us enough information to identify your account and prove that you are the owner of the identified account. We will endeavor to act on these deletion requests as soon as reasonably practicable. Deletions take effect when we act on them. Deletion of your personally identifiable information does not include any personally identifiable information that we maintain on backup servers or backup storage devices for disaster recovery, record keeping, or as required by law.  You understand that if your personally identifiable information is deleted at your request, you will be unable to access the Site or receive services from Questis.  Deletion of your personally identifiable information as described above does not include any non-PII, all of which Questis may retain in its sole discretion.
      You may decline to provide personally identifiable information to Questis. Declining to provide personally identifiable information may disqualify you for Questis services and access to the Site.
    </p>
  </p>

  <p>
    <span>
      2. You may decline to provide personally identifiable {''}
    </span>
    <p className="pl-4">
      information to Questis. Declining to provide personally identifiable information may disqualify you for Questis services and access to the Site.
    </p>
  </p>

  <h2><strong>Changes to this Policy Since the Effective Date</strong></h2>
  <p>This Privacy Policy may change from time to time. We will post any material Privacy Policy changes on this page.  For certain changes we may also provide a more prominent notice such as email notification or notification on the Site. Each version of this Privacy Policy will be identified at the top of the page by its update date.</p>
  <p>Changes on February 1, 2020:</p>
  <ul className="list-disc pl-5">
    <li>
      <strong>
        Removed clauses pertaining to clients for trading services, as these services are no longer offered by Questis either directly or through any third-party relationships with brokers.
      </strong>
    </li>
  </ul>


  <ul className="list-disc pl-5">
    <li>
      <strong>
        Clarified that Questis has the right to transfer User information in connection with a sale of the company or other divestiture of assets.
      </strong>
    </li>
  </ul>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        Clarified that the policy applies to Users’ personally identifiable information, such as name and home address.
      </strong>
    </li>
  </ul>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        Added clauses pertaining to jurisdiction and cross-border data transfer.
      </strong>
    </li>
  </ul>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        Clarified that this policy applies to “personally identifiable information,” such as a User’s name.
      </strong>
    </li>
  </ul>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        Clarified the distinction between PII and non-PII.
      </strong>
    </li>
  </ul>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        Clarified that Questis may retain copies of PII indefinitely as part of its regular data backup and recovery and as required by law.
      </strong>
    </li>
  </ul>

  <ul className="list-disc pl-5">
    <li>
      <strong>
        Clarified that deletion of PII does not apply to non-PII.
      </strong>
    </li>
  </ul>

  <h2><strong>Jurisdictional Issues</strong></h2>

  <p>The Site is controlled and operated by us from the United States and is not intended to subject us to the laws or jurisdiction of any state, country or territory other than that of the United States.</p>
  <h2><strong>Cross-border Transfer</strong></h2>
  <p>Your personally identifiable information may be stored and processed in any country where we have facilities or in which we engage service providers, and, by using the Site, you consent to the transfer of information to countries outside of your country of residence, including the United States, which may have data protection rules that are different from those of your country.</p>

  <h2><strong>More Information</strong></h2>

  <p>If you have questions or comments about this Privacy Policy, please email us at   <a href="mailto:support@myquestis.com" className="text-blue600 hover:underline">
    support@myquestis.com
  </a>.</p>
  <p>You can also contact Questis by phone at 855.843.5233.</p>

</div>);


export const TERMS_AND_PRIVACY = {
  terms: {
    title: "Terms",
    content: TermsContent,
  },
  privacy: {
    title: "Privacy Policy",
    content: PrivacyContent,
  },
};

