import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import AppLogo from "../../../assets/AppLogo";
import { CategoryLimitComponent } from "./CategoryLimitComponent";
import Ellipse from "./Ellipse";
import { AppDispatch, RootState } from "../../../store/store";
import { spendingAdjustmentsAsync, editSpendingLimitAsync, saveSpendingLimitFlagAsync } from "../../../store/thunks/SpendingThunk";
import { roundUp } from "../../../utils/helper";
import { SpendingLimitTitleMessage } from "./SpendingLimitTitleMessage";
import { categoriesImage } from "../../common/categories";
import EditModal from "./EditModal";
import { fetchAccountDetailAsync, fetchAnalyzeDataAsync, fetchSalaryAsync } from "../../../store/thunks/TransactionThunk";


const SendingLimitsOverviewComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const spendingState = useSelector((state: RootState) => state.TransactionStore.spendingState);
    const { spendingAdjustmentsResponse } = useSelector((state: RootState) => state.spendingStore);

    const [spendingAdjustments, setSpendingAdjustments] = useState(spendingAdjustmentsResponse || []);
    const [originalAdjustments, setOriginalAdjustments] = useState(spendingAdjustmentsResponse || []);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [amount, setAmount] = useState(0);
    const [originalAmount, setOriginalAmount] = useState(0);

    useEffect(() => {
        const fetchAnalyzeData = async () => {
            await dispatch(fetchAnalyzeDataAsync());
        };
        fetchAnalyzeData();

    }, [dispatch]);
    useEffect(() => {
        const fetchSalary = async () => {
            await dispatch(fetchSalaryAsync());
        };
        fetchSalary();

    }, [dispatch]);

    useEffect(() => {
        const fetchSpendingAdjustments = async () => {
            await dispatch(spendingAdjustmentsAsync());

            // if (response?.payload) {
            //     setSpendingAdjustments([...response.payload]);
            //     setOriginalAdjustments([...response.payload]);
            // }
        };
        fetchSpendingAdjustments();
    }, [dispatch]);

    useEffect(() => {
        if (spendingAdjustmentsResponse) {
            setSpendingAdjustments([...spendingAdjustmentsResponse]);
            setOriginalAdjustments([...spendingAdjustmentsResponse]);
        }
    }, [spendingAdjustmentsResponse]);

    const totalSavedAmount = spendingAdjustments.reduce(
        (sum, item) => sum + roundUp(item.originalAmount - item.recommendedAmount, 2),
        0
    );

    const spentAmount =
        (spendingState?.monthlyAvgIncome ?? 0) - (spendingState?.monthlyAvgSpending ?? 0);


    const handleCategoryClick = (category: any) => {
        setSelectedCategory(category);
        setAmount(category.recommendedAmount);

        // Find the matching category in originalAdjustments and set the original amount
        const matchingCategory = originalAdjustments.find(
            (item) => item.finleyCategory === category.finleyCategory
        );
        setOriginalAmount(matchingCategory?.recommendedAmount || category.recommendedAmount);

        setShowEditModal(true);
    };


    const handleNumberClick = (num: number | ".") => {
        setAmount((prev: any) => {
            const prevString = prev.toString();
            if (prevString === "0" || prevString === "") {
                return num === "." ? "0." : num.toString();
            }
            if (num === ".") {
                if (prevString.includes(".")) return prev;
                return `${prevString}.`;
            }
            return (prevString + num);
        });
    };

    const handleBackspace = () => {
        setAmount((prev) => {
            const prevString = prev.toString();
            const updatedString = prevString.slice(0, -1) || "0";
            return parseFloat(updatedString);
        });
    };

    const handleSave = () => {
        if (selectedCategory) {
            setSpendingAdjustments((prev) =>
                prev.map((item) =>
                    item.finleyCategory === selectedCategory.finleyCategory
                        ? { ...item, recommendedAmount: amount, drasticallyAdjusted: true }
                        : item
                )
            );
        }
        closeModal();
    };

    const handleSaveLimits = async () => {
        const limits = spendingAdjustments.map((item) => ({
            finley_category: item.finleyCategory,
            amount: Number(item.recommendedAmount),
        }));
        await dispatch(editSpendingLimitAsync({ limits }));
        await dispatch(fetchAccountDetailAsync());
        navigate('/home');
    };

    const handleSkipForNow = async () => {
        setSpendingAdjustments([...originalAdjustments]);
        await dispatch(saveSpendingLimitFlagAsync());
        await dispatch(fetchAccountDetailAsync());
        navigate('/home');
    };

    const closeModal = () => {
        setShowEditModal(false);
        setSelectedCategory(null);
    };

    const getIcon = (category: string) => {
        const match = categoriesImage.find(
            (item) => item.categoryName.toUpperCase() === category
        );
        return match ? match.icon : "❓";
    };

    return (
        <>
            <div className="flex flex-col h-screen">
                <div className="flex flex-col min-h-screen ">
                    <header className="sticky top-0">
                        <div className="flex justify-center items-center mx-auto pt-4 pb-3 sticky top-0">
                            <AppLogo />
                            <div className="ml-2 carouselTitle">
                                <h4>Finley</h4>
                            </div>
                        </div>
                    </header>
                    <main className="flex-1 overflow-auto">
                        <div>
                            <div className="flex flex-col p-3 items-start">
                                <div className="textlarge700 mb-3">
                                    <SpendingLimitTitleMessage
                                        overSpendingAmount={spentAmount}
                                        recommendedAmount={totalSavedAmount}
                                    />
                                </div>
                                <div className="flex p-5 min-h-[452px] flex-col items-center gap-3 self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white">
                                    <div className="flex text-center">
                                        <Ellipse
                                            amount={totalSavedAmount}
                                            title={
                                                totalSavedAmount < 0
                                                    ? "Overspending by"
                                                    : spentAmount < 0
                                                        ? "Save"
                                                        : "Free up"
                                            }
                                        />

                                    </div>
                                    <div className="flex flex-col flex-grow">
                                        {[...spendingAdjustments]
                                            .sort((a, b) => b.recommendedAmount - a.recommendedAmount)
                                            .map((item, index) => {

                                                return (
                                                    <>
                                                        {item.originalAmount - item.recommendedAmount > 0 &&
                                                            <div
                                                                onClick={() => handleCategoryClick(item)}
                                                                className="pb-3"
                                                                key={item.finleyCategoryName}
                                                            >
                                                                <CategoryLimitComponent
                                                                    savedAmount={`${roundUp((item.originalAmount - item.recommendedAmount), 2)}`}

                                                                    categoryName={item.finleyCategoryName}
                                                                    limitAmount={`${roundUp(item.recommendedAmount, 2)}`}
                                                                    imageName={item.finleyCategory.toLowerCase()}

                                                                    monthlyAvgIncome={spendingState.monthlyAvgIncome}
                                                                />
                                                            </div>}
                                                    </>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <div className="sticky bg-white bottom-0 left-0 right-0 h-[130px] pt-3 w-full pb-[25px] px-[25px] border border-t-[1px] border-neutral-200">
                        <button
                            onClick={handleSaveLimits}
                            className={`px-4 py-2 text-white rounded-full text3 w-full
                            ${totalSavedAmount < 0 ? 'bg-disabledButton  cursor-not-allowed opacity-50' : 'bg-enabledButton cursor-pointer opacity-100'}`}
                            disabled={totalSavedAmount < 0}
                        >
                            Start My New Plan
                        </button>
                        <div onClick={handleSkipForNow} className="pt-2 pb-[30px] text-center text3">
                            <button>Skip for now</button>
                        </div>
                    </div>
                </div>
            </div>

            <EditModal
                isVisible={showEditModal}
                category={selectedCategory}
                amount={amount}
                originalAmount={originalAmount}
                onNumberClick={handleNumberClick}
                onBackspace={handleBackspace}
                onSave={handleSave}
                onClose={closeModal}
                getIcon={getIcon}
            />
        </>
    );
};

export default SendingLimitsOverviewComponent;
