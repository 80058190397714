import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountDetailAsync, fetchLinkTokenAsync } from "../../store/thunks/TransactionThunk";
import { AppDispatch, RootState, store } from "../../store/store";
import useDeviceInfo from '../../hooks/useDeviceInfo';
import { STATUS } from '../../types/CommonType';
import { PlusIcon } from '@heroicons/react/20/solid';
import AlertMessage from '../common/AlertMessage';
import Spinner from '../common/Spinner';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { Button } from '@headlessui/react';
import { getUserAccountDetailsAsync } from '../../store/thunks/MasterThunk';
import { resetLinkToken } from '../../store/slices/TransactionSlice';
interface PlaidLinkComponentProps {
  divClassName?: string; // optional prop for the div's CSS classes
  plusIconClassName?: string; // optional prop for the PlusIcon's CSS classes
  buttonClassName?:string,
  accountCountFromParent?: number;
  setIsLoading: (loading: boolean) => void;


}
const PlaidLinkComponent: React.FC<PlaidLinkComponentProps> = ({
  divClassName = 'flex justify-center items-center p-2 gap-2', 
  plusIconClassName = 'h-4 w-4 sm:h-5 sm:w-5' ,
  buttonClassName='',
  setIsLoading,
  accountCountFromParent=0

})  => {
  const dispatch: AppDispatch = useDispatch();
  const deviceInfo = useDeviceInfo();
  const finleyUrl =  process.env.REACT_APP_PLAID_CALLBACK_URL+'callback'; 
  const { link_token, hostedLink, linkStatus, accounts } = useSelector(
    (state: RootState) => state.TransactionStore
  );
  const [initialAccountCount, setInitialAccountCount] = useState<number>(0);
  const [alertMessage, setAlert] = useState<{
    message: string;
    type: 'success' | 'error' | null;
    title: string;
  }>({
    message: '',
    type: null,
    title: '',
  });
  const [isLinkOpened, setIsLinkOpened] = useState(false); // Track if the link has been opened

  useEffect(() => {

    const fetchAccountDetails = async () => {
      try {
        // Dispatch the action and wait for the result
        const resultAction = await dispatch(fetchAccountDetailAsync());

        // Check if the action was fulfilled
        if (resultAction && resultAction.type === fetchAccountDetailAsync.fulfilled.type) {
          // Action was fulfilled, handle it

          setInitialAccountCount(resultAction.payload?.length || 0);
 
        }
      } catch (error) {
        console.error("Failed to fetch account details:", error);
      }
    };
  
    // Call the fetchAccountDetails function
    fetchAccountDetails();
  }, []); // Empty dependency array to run only on mount
  useEffect(() => {
    if (link_token && hostedLink && !isLinkOpened) {
      const linkUrl = `${hostedLink}?link_token=${link_token}`;
     // console.log('Plaid Link URL:', linkUrl);

   
      Browser.open({ url: linkUrl }).then(() => {
        setIsLinkOpened(true); // Mark as opened after the Browser opens the link
      });
    }
  }, [link_token, hostedLink, isLinkOpened]);
  App.addListener('appUrlOpen', (event) => {
    // console.log('App URL Opened:', event.url);
  });
  const handleLinkConnect = async () => {
    let body: {
      is_mobile_app?: boolean;
      redirect_uri?: string;
      completion_redirect_uri?: string;
    } = {};
    if (deviceInfo?.platform !== 'web') {
      body = {
        // is_mobile_app: true,
        // redirect_uri: finleyUrl + 'callback',
        // completion_redirect_uri: "com.dev.finley://" ,
        is_mobile_app: false,
        //completion_redirect_uri: finleyUrl + 'callback',
      };
    }
    else {
      body.is_mobile_app = false;
      body.completion_redirect_uri = finleyUrl ;
    }
    await dispatch(fetchLinkTokenAsync(body));
    setIsLinkOpened(false); // Allow link to be reopened when a new token is fetched
  };
  const handleCloseAlert = () => {
    setAlert({ message: '', type: null, title: '' });
  };
  useEffect(() => {
    let browserFinishedHandle: PluginListenerHandle | undefined;
    //let count = 0;
    const setupListeners = async () => {
      setInitialAccountCount(accounts?.length || 0);
      
      browserFinishedHandle = await Browser.addListener('browserFinished', async () => {
        let count = 0;
        setInitialAccountCount(accounts?.length || 0);
        dispatch(resetLinkToken());
        dispatch(getUserAccountDetailsAsync());
        setIsLinkOpened(true);
        setIsLoading(true);
  
        // Wait for each fetchAccountDetailAsync to finish before starting the next one
        const interval = setInterval(async () => {
          // const latestAccounts = store.getState().TransactionStore.accounts;
          // console.log('fetchAccountDetailAsync ' + count);
          // console.log('accounts?.length', latestAccounts?.length)
          // console.log('accountCountFromParent',accountCountFromParent);
          // console.log('(accountCountFromParent && latestAccounts && latestAccounts?.length>accountCountFromParent)', (latestAccounts?.length||0>accountCountFromParent))
          if (count >= 15 ) {
            setIsLoading(false);
            clearInterval(interval);
            return;  
          }
          count++;
          await dispatch(fetchAccountDetailAsync()); 
          
  
        }, 250);
      });
    };
  
    setupListeners();
  
    return () => {
      browserFinishedHandle?.remove();
    };
  }, [dispatch, accounts?.length]);
  return (
    <div className={divClassName}>
      {linkStatus === STATUS.LOADING ? (
        <Spinner />
      ) : (
        <Button className={buttonClassName}
          onClick={handleLinkConnect}
          disabled={linkStatus === STATUS.LOADING}
        >
          <PlusIcon className={plusIconClassName} />
        </Button>
      )}
      {alertMessage.type && (
        <AlertMessage
          open={!!alertMessage.type}
          title={alertMessage.title}
          message={alertMessage.message}
          type={alertMessage.type}
          onClose={handleCloseAlert}
        />
      )}
    </div>
  );
};
export default PlaidLinkComponent;