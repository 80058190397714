import React, { useEffect, useState } from 'react';
import EditIconSmall from '../../../assets/EditIconSmall';

import { FinleyCategorySpentData, resetCategoryLimitStatus, SPENDSTATUS } from '../../../store/slices/SpendingsSlice';
import RadialImageChart from '../Charts/RadialImageChart';
import { formatCurrency } from '../../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { categoriesImage } from '../../common/categories';
import DrawerComponent from '../../common/DrawerComponent';
import { postCategorySpendLimitAsync } from '../../../store/thunks/TransactionThunk';
import { fetchCategoryLimitAsync, fetchCategorySpendsAsync, spendingAdjustmentsAsync } from '../../../store/thunks/SpendingThunk';
import NumberKeypad from '../../common/NumberKeyPad';


interface SpendCategoryDataProps {
  selectedCategory: any | null;
  setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
}

const SpendCategoryData: React.FC<SpendCategoryDataProps> = ({
  selectedCategory,
  setSelectedCategory,

}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [editLimitAmount, setEditLimitAmount] = useState<number | null>(null);
  const [editCategory, setEditCategory] = useState<string | null>(null);
  const [editClicked, setEditClicked] = useState(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState<any | null>(null); // State for filtered category data
  const { categorySpendingLimit, categorySpendingLimitStatus, fetchCategorySpentResponse } = useSelector((state: RootState) => state.spendingStore);


  const closeModal = () => {
    setShowEditModal(false);
  };

  useEffect(() => {
    dispatch(fetchCategoryLimitAsync(selectedCategory.finleyCategory));
  }, [dispatch, selectedCategory.finleyCategory, editClicked]);

  useEffect(() => {
    dispatch(fetchCategorySpendsAsync);
  }, [dispatch]);

  useEffect(() => {
    if (fetchCategorySpentResponse && selectedCategory?.finleyCategory) {
      const filteredCategory = fetchCategorySpentResponse.find(
        (x) => x.finleyCategory === selectedCategory.finleyCategory
      );
      setSelectedCategoryData(filteredCategory);
    }
  }, [fetchCategorySpentResponse, selectedCategory.finleyCategory]);

  useEffect(() => {
    if (editClicked)
      setShowEditModal(true);
  }, [editClicked]);

  const fetchSelectedCategorySpends = () => {
    const category = fetchCategorySpentResponse?.find(x => x.finleyCategory == selectedCategory.finleyCategory)
    setSelectedCategory(category);
    return category
  }

  const handleEditClick = async (category: string, amount: number) => {
    setEditClicked(false);
    dispatch(resetCategoryLimitStatus());
    const resultAction = await dispatch(fetchCategoryLimitAsync(selectedCategory.finleyCategory));
    setEditClicked(true);
    setEditCategory(category);
    setEditLimitAmount(categorySpendingLimit?.amount || 0);
    dispatch(resetCategoryLimitStatus());
  };

  const handleSave = async (amt: any) => {
    if (!editCategory || editLimitAmount === null) return;

    const formData = {
      limits: [
        {
          finley_category: editCategory,
          amount: amt,
        },
      ],
    };

    try {
      // Wait for the async action to complete
      const resultAction = await dispatch(postCategorySpendLimitAsync(formData));
      if (postCategorySpendLimitAsync.fulfilled.match(resultAction)) {
        // Fetch updated category spends immediately after successful action
        await dispatch(fetchCategorySpendsAsync());

        await dispatch(fetchCategoryLimitAsync(selectedCategory.finleyCategory));

        if (selectedCategory) {

          const cat = fetchSelectedCategorySpends();
          setSelectedCategory(cat);

        }
      } else {
        console.error("Failed to update spending limit", resultAction.payload);
      }
    } catch (error) {
      console.error("Error saving spending limit:", error);
    } finally {
      setShowEditModal(false);
    }
  };


  const editSpendingLimit = (
    <div className=''>
      <NumberKeypad buttonLabels='Save spending limit' value={editLimitAmount} onSave={handleSave}></NumberKeypad>
    </div>
  );
  const selectedCategoryLimitData = useSelector(
    (state: RootState) => state.TransactionStore.spendCategoryTransactions
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const color =
    selectedCategoryData?.spendStatus === SPENDSTATUS.OVER_SPENT
      ? 'red'
      : selectedCategoryData?.spendStatus === SPENDSTATUS.OVER_THRESHOLD_SPENT
        ? '#FACC15'
        : '#5DE093';
  const categoryData = categoriesImage.find(
    (item) => item.categoryName.toLowerCase() === selectedCategory?.finleyCategory.toLowerCase()
  );

  return (
    <>
      <div className=" pt-3 h-screen" >
        <div className="bg-white  ">
          <div className="flex justify-center mt-6">
            <RadialImageChart
              percentage={selectedCategoryData?.spendPercentage ? selectedCategoryData.spendPercentage > 100 ? 100 : selectedCategoryData?.spendPercentage : selectedCategoryData?.spendPercentage} // Progress percentage
              size={128.58}
              strokeWidth={11}
              fontSize={50}
              emoji={categoryData?.icon}
              color={color}
            />
          </div>
          <div className='texth4 flex justify-center mt-2'>{formatCurrency(selectedCategoryData?.categorySpend || 0)} spent</div>
          <div className='flex justify-center items-center mt-1'>
            <div className='chatDay mr-1 mt-0.5'>{formatCurrency(selectedCategoryData?.spendRemaining<0?0: selectedCategoryData?.spendRemaining || 0)} left this month </div>
            <div onClick={() => handleEditClick(selectedCategoryData?.finleyCategory || '', selectedCategoryData?.spendRemaining || 0)}><EditIconSmall /></div>
          </div>
          <div className='mt-6 px-4'>
            <div className='textlarge700'>
              Overview
            </div>
            <div className='flex text-neutral500 intertextxs justify-between mt-4'><div>Total spent this year</div><div>{formatCurrency(parseFloat(selectedCategoryLimitData?.totalSpending||"0"))}</div></div>
            <div className='flex text-neutral500 intertextxs justify-between mt-4'><div>Average per month </div><div>{formatCurrency(parseFloat(selectedCategoryLimitData?.avgSpending||"0"))}</div></div>
          </div>
          <div className="px-4  pb-36 flex-grow hide-scrollbar mt-6 mb-6">

            {selectedCategoryLimitData?.data?.map((monthData: any) => (
              <div key={monthData.month} className="">
                {/* Month Header */}
                <div className="chatDay text-neutral500 ">
                  {new Date(monthData.month + "T00:00:00Z").toLocaleString("en-US", { month: "long", year: "numeric", timeZone: "UTC" })}
                </div>
                <div>
                  {/* Transactions */}
                  {monthData.transactions.map((transaction: any, index: any) => (
                    <div
                      key={`${monthData.month}-${index}`}
                      className="w-full flex p-3 justify-between items-center self-stretch border border-borderGrey rounded-2xl my-1"
                    >
                      {/* Merchant Details */}
                      <div className="flex flex-col items-start">
                        <div className="text3 text-darkBlue">{transaction.name || "Unknown Merchant"}</div>
                        <div className="intertextxs text-neutral-500">
                          {transaction.finleyCategoryName || "N/A"}
                        </div>
                      </div>

                      {/* Transaction Details */}
                      <div className="flex flex-col items-end">
                        <div className={`text3 ${(transaction.amount < 0) ? "text-[#22C55E]" : "text-darkBlue"}`}>
                          {(transaction.amount < 0)
                            ? formatCurrency(Math.abs(transaction.amount))
                            : formatCurrency(transaction.amount)}
                        </div>
                        <div className="intertextxs text-neutral-500">
                          {new Date(transaction.transactionDate).toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                          })}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <DrawerComponent
          isOpen={
            showEditModal
          }
          onClose={closeModal}
          title=''
          content={editSpendingLimit}
        />
      </div>
    </>
  );
};

export default SpendCategoryData;

