import { default as http } from "../rest/http";

export const spendingAdjustment = async () => {
  try {
    const response = await http.get("/transaction-service/spending/adjustments");
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


export const editSpendingLimitService = async (data:any) => {
  try {
    const response = await http.post("/transaction-service/spending/limits",data);
    return response;
  } catch (error) {
    console.error("Error saving account:", error);
    throw error;
  }
};
export const fetchDailySpends = async () => {
  try {
    const response = await http.get("/transaction-service/spending/daily-spending");
    return response;
  } catch (error) {
    console.error("Error saving account:", error);
    throw error;
  }
};


export const fetchCategorySpends = async () => {
  try {
    const response = await http.get("/transaction-service/spending/category-spending");
    return response;
  } catch (error) {
    console.error("Error saving account:", error);
    throw error;
  }
};

export const fetchCategoryLimit = async (finley_category:string) => {
  try {
    const response = await http.get(`/transaction-service/spending/limits/${finley_category}`);
    return response;
  } catch (error) {
    console.error("Error saving account:", error);
    throw error;
  }
};

export const saveSpendingLimitFlag = async () => {
  try {
    const response = await http.post("/master-data/account/spending-limit-flag ",null);
    return response;
  } catch (error) {
    console.error("Error saving account:", error);
    throw error;
  }
};


