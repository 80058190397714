import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from "../../../store/store";
import { resetEmailVerificationStatusCode, setStepValid, updateFormData, validateStep } from "../../../store/slices/OnBoardingSlice";
import { VerifyEmailAsync } from '../../../store/thunks/OnBoardingThunk';
import { HttpStatus, MESSAGES, STATUS } from '../../../types/CommonType';
import { loginUserAsync } from '../../../store/thunks/AuthThunk';
import { Button, Input } from '@headlessui/react';
import MessageComponent from '../../common/MessageComponent';
import { useNavigate } from 'react-router';
import PasswordInput from '../../common/PasswordInput';

type StepProps = {
    onNext: () => void;
};

const Step1 = forwardRef<HTMLDivElement, StepProps>(({ onNext }, ref) => {
    const dispatch = useDispatch<AppDispatch>();
    const loginDetails = useSelector((state: RootState) => state.AuthStore);
    const email = useSelector((state: RootState) => state.OnboardingStore.email);
    const emailVerificationStatus: number | undefined = useSelector((state: RootState) => state.OnboardingStore.emailVerificationStatusCode);
    const isValid: boolean | undefined = useSelector((state: RootState) => state.OnboardingStore.isValid.step1);

    //dispatch(validateStep({ step, isValid }));
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');

    const [isVerifying, setIsVerifying] = useState(false);
    const navigate = useNavigate();


    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateFormData({ email: event.target.value }));
        dispatch(resetEmailVerificationStatusCode());
        setError('');
    };

    useEffect(() => {
        if (isVerifying && emailVerificationStatus === HttpStatus.Success) {
            setIsVerifying(false);
            dispatch(validateStep({ step: 'step1', isValid: true }));

            if (emailVerificationStatus === HttpStatus.Success) {
                setTimeout(() => {
                    onNext();
                }, 800);
            }
            else if (emailVerificationStatus === HttpStatus.Conflict) {
                setError(MESSAGES.ALREADY_ONBOARDED_USER_MESSAGE);

            }
        }
    }, [emailVerificationStatus, isVerifying, onNext]);

    useEffect(() => {
        const fetchData = async () => {
            if (loginDetails.loginStatus === STATUS.SUCCESS) {
                const accessToken: string | undefined = loginDetails?.loginResponse?.access_token;
                localStorage.setItem('accessToken', accessToken as string);
                // Redirect or perform any other logic here
                navigate('/home')
            } else if (loginDetails.loginStatus === STATUS.FAILED) {
                dispatch(resetEmailVerificationStatusCode());
                setError(MESSAGES.LOGIN_FAILED_MESSAGE);
            }
        };

        fetchData();
    }, [loginDetails]);


    const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleBlur = () => {
        if (email.trim() === '') {
            setError(MESSAGES.EMAIL_EMPTY);
            dispatch(setStepValid(false));
        } else if (!validateEmail(email)) {
            setError(MESSAGES.EMAIL_EMPTY);
            dispatch(setStepValid(false));
        } else {
            setError('');
            dispatch(setStepValid(true));
        }
    };

    const handleNext = async () => {
        if (!error && email) {
            await dispatch(VerifyEmailAsync({ email: email }));
            setIsVerifying(true);
            // if (emailVerificationStatus === HttpStatus.Success) {
            //     setTimeout(() => {
            //         onNext();
            //     }, 800);
            // }
        }
    };

    const handleLogin = async () => {
        await dispatch(loginUserAsync({ email: email, password: password }));
    };

    // const handleToggle = () => {
    //     setType(type === 'password' ? 'text' : 'password');
    //     setIcon(type === 'password' ? <Eye className='h-[24px] w-[24px] ' size={20} /> : <EyeOff size={20} />);
    // };

    // Extracted Functions for MessageComponent

    const renderErrorMessage = () => {
        return error && (
            <div className='pt-3'>
                <MessageComponent type="Error" message={error} />
            </div>
        );
    };

    const renderOnboardedMessage = () => {

        return emailVerificationStatus === HttpStatus.Conflict && (
            <div className='pt-3'>
                <MessageComponent type="Info" message={MESSAGES.ALREADY_ONBOARDED_USER_MESSAGE} />
            </div>
        );
    };

    const renderUnauthorisedMessage = () => {
        return (emailVerificationStatus === HttpStatus.Unauthorized || (emailVerificationStatus === HttpStatus.NotFound && email)) && (
            <div className='pt-3'>
                <MessageComponent type="Error" message={MESSAGES.UNAUTHORIZER_USER_MESSAGE} />
            </div>
        );
    };


    const renderPasswordInput = () => {
        return (emailVerificationStatus === HttpStatus.Conflict || error === MESSAGES.LOGIN_FAILED_MESSAGE) && (
            <>
                <div className='pt-3 text3'>Password</div>
                <PasswordInput
                    password={password}
                    setPassword={setPassword}
                />
            </>
        );
    };

    // Render Button Based on Status
    const renderButton = () => {
        const buttonText = emailVerificationStatus === HttpStatus.Conflict || error === MESSAGES.LOGIN_FAILED_MESSAGE ? 'Sign In' : 'Activate Your Benefit';
        const handleClick = emailVerificationStatus === HttpStatus.Conflict || error === MESSAGES.LOGIN_FAILED_MESSAGE ? handleLogin : handleNext;
        const isButtonDisabled =
            buttonText === 'Activate Your Benefit' &&
            (!email || !!error || !validateEmail(email));
        return (
            <Button
                onClick={handleClick}
                className={`flex justify-center items-center w-full text3 text-white py-2 rounded-full 
                    ${isButtonDisabled ? 'bg-disabledButton/50 cursor-not-allowed opacity-50' : 'bg-enabledButton cursor-pointer opacity-100'}`}
                disabled={isButtonDisabled}
            >
                {buttonText}
            </Button>

        );
    };
    const renderForgotPassword = () => {
        const text = emailVerificationStatus === HttpStatus.Conflict || error === MESSAGES.LOGIN_FAILED_MESSAGE ? 'Forgot Password?' : '(You can always switch to a personal one later!)';

        return (
            <div className="intertext1 pt-3 pb-6">{text}</div>
        );
    };

    return (
        <div
            className={`transition-opacity duration-300 ${!isValid ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'}`}>

            <div className="text-darkBlue font-serif text2" >Hello, I’m Finley. </div>
            <div className="text3 pt-3">
                I'm here to be your financial companion. Think of me as your smart friend who's really good with money and always ready to help.
                <p className="pt-3">Before we dive in, let me confirm that you can access Finley for free through your employer. Could you share your work email?</p>
            </div>

            <div className="pt-3">
                <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleBlur}
                    className="text4 text-slate-900 bg-white rounded-md w-full border border-slate-300 h-[36px] px-3  focus:border-slate-300 focus:outline-none"
                    placeholder="Enter your work email"
                    required
                />
                {renderErrorMessage()}
            </div>

            {renderOnboardedMessage()}
            {renderUnauthorisedMessage()}
            {renderPasswordInput()}

            <div className="w-full pt-3" >{renderButton()}</div>

            {/* <div className="intertext1 pt-3 pb-6">We’ll switch to your personal email later on.</div> */}
            {renderForgotPassword()}
            <div ref={ref}></div>
        </div>
    );
});

export default Step1;


