import React, { useState } from 'react';
import SendIcon from '../../assets/SendIcon'; // Assuming SendIcon is a separate component
import { Textarea } from '@headlessui/react';

interface ChatInputProps {
    isLoading: boolean;
    send: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ isLoading, send }) => {
    const [inputValue, setInputValue] = useState<string>('');

    // Handle Enter key press to send the message
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !isLoading) {
            send(inputValue);
            setInputValue('');
            event.preventDefault(); // Prevent default behavior of Enter (new line)
        }
    };

    return (

        <div className="bg-background w-full flex justify-center lg:px-0 text4">
            <div className="relative w-full flex items-center">
                <Textarea
                    value={inputValue}
                    onKeyDown={handleKeyDown}
                    onChange={(event) => setInputValue(event.target.value)}
                    onInput={(e) => {
                        const textarea = e.target as HTMLTextAreaElement;
                        textarea.style.height = '40px'; // Reset to default height
                        textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content
                    }}
                    placeholder="Ask me anything about your money"
                    className="px-4 pr-10 py-3 w-full resize-none rounded-md overflow-hidden border"
                    style={{ height: '48px', borderColor: '#E5E5E5' }}
                />
                <div
                    onClick={() => !isLoading && send(inputValue)}
                    className="absolute right-3 cursor-pointer"
                >
                    <SendIcon disabled={isLoading} />
                </div>
            </div>
        </div>

    );
};

export default ChatInput;
