import React, { useState } from "react";
import KeypadBackIcon from "../../assets/KeypadBackIcon";

interface NumberKeypadProps {
  value: number | null;
  onSave: (data:any) => void;
  buttonLabels?:string;
  labelAboveAmount?:string;
  labelBelowAmount?:string;
}

const NumberKeypad: React.FC<NumberKeypadProps> = ({ value, onSave ,buttonLabels='Save', labelAboveAmount='Spending Limit', labelBelowAmount='monthly'}) => {
  const [currentValue, setCurrentValue] = useState<number | null>(value);

  // Function to handle number click
  const handleNumberClick = (num: number | ".") => {
    setCurrentValue((prev: any) => {
      if (prev === null) prev = 0;
      const prevString = prev.toString();
      if (num === ".") {
        if (prevString.includes(".")) return prev; // Prevent multiple decimal points
        return `${prevString}.`;
      }
      return parseFloat(prevString + num); // Concatenate the number
    });
  };

  // Function to handle backspace
  const handleBackspace = () => {
    setCurrentValue((prev) => {
      if (prev === null || prev.toString() === "0") return 0;
      const prevString = prev.toString();
      return prevString.length > 1 ? parseFloat(prevString.slice(0, -1)) : 0;
    });
  };

  // Buttons text array
  const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "<"];

  return (
    <>
     <div className="flex justify-center">
          <div
            className="flex items-center justify-center" >
            <div className="flex flex-col text-center mt-4">
              <span className="text-green text3">{labelAboveAmount}</span>
              <span className="text-green mt-5 spendingEditText">
                ${currentValue}
              </span>
              <span className="text-green intertextmediumparagraph mt-5">{labelBelowAmount}</span>
            </div>
          </div>
        </div>
      
    <div className="mt-24">
      {/* Render the keypad buttons */}
      <div className="grid grid-cols-3 gap-10">
        {buttons.map((key: any, index) => (
          <button
            key={index}
            className="text6 flex justify-center items-center"
            onClick={() => {
              if (key === "<") {
                handleBackspace();
              } else {
                handleNumberClick(key);
              }
            }}
          >
            {key === "<" ? <KeypadBackIcon /> : key}
          </button>
        ))}
      </div>



      {/* Save button */}
      <button
        className="mt-10 bg-black text-white h-10 w-full rounded-full text-lg font-semibold"
        onClick={() => onSave(currentValue)}
      >
        {buttonLabels}
      </button>
    </div>
    </>
  );
};

export default NumberKeypad;
