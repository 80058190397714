import InfoIcon from '../../assets/Info.svg';
import SuccessIcon from '../../assets/CheckCircle.svg';
import ErrorIcon from '../../assets/Error.svg';

type Props = {
  type: 'Info' | 'Error' | 'Success'; 
  message: string|React.ReactNode; 
};

const MessageComponent = ({ type,message }: Props) => {
  return (
    <div className="">
      {/* Info Message */}
      {type === 'Info' && (
        <div className="flex  p-[10px] rounded-s border bg-neutral-50 border-neutral-200">
          <div className="flex-shrink-0">
           <img className="w-4 h-4" src={InfoIcon} alt="" />
         </div>
         <div className="ml-2 text4 text-neutral-600 whitespace-pre-line">
            {typeof message === 'string' ? (
              <span dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
              message
            )}
          </div>
        </div>
      )}

      {/* Error Message */}
      {type === 'Error' && (
        <div className="flex  p-[10px] bg-red-50 rounded-s border border-red-200">
          <div className="flex-shrink-0">
           <img className="w-4 h-4 text-red-600" src={ErrorIcon} alt="Success Icon" />
         </div>
          <div className="ml-2 text4 text-red-600 whitespace-pre-line">
          {typeof message === 'string' ? (
              <span dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
              message
            )}
          </div>
        </div>
      )}

      {/* Success Message */}
      {type === 'Success' && (
         <div className="flex p-[10px] bg-[#F0FDF4] rounded border border-[#A7F3D0]">
         {/* Icon Section */}
         <div className="flex-shrink-0">
           <img className="w-4 h-4" src={SuccessIcon} alt="Success Icon" />
         </div>
         {/* Text Section */}
         <div className="ml-2 text-neutral-600 text4 whitespace-pre-line" >
         {typeof message === 'string' ? (
              <span dangerouslySetInnerHTML={{ __html: message }} />
            ) : (
              message
            )}
         </div>
       </div>
      )}
    </div>
  );
};

export default MessageComponent;
