const HomeIcon: React.FC<{ color: string }> = ({ color }) => {
    return (
        <>
            {color === "#22C55E" ?
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.10742 2.85356C10.0495 2.16435 10.9512 1.75 12 1.75C13.0488 1.75 13.9505 2.16435 14.8926 2.85357C15.8068 3.52238 16.8365 4.50981 18.1359 5.75572L18.1709 5.78932L20.75 8.17179V13.5565C20.75 15.3942 20.75 16.8498 20.5969 17.989C20.4392 19.1614 20.1071 20.1104 19.3588 20.8588C18.6104 21.6071 17.6614 21.9392 16.489 22.0969C15.3498 22.25 13.8942 22.25 12.0564 22.25H11.9435C10.1058 22.25 8.65018 22.25 7.51098 22.0969C6.33856 21.9392 5.38961 21.6071 4.64124 20.8588C3.89288 20.1104 3.56076 19.1614 3.40314 17.989C3.24997 16.8498 3.24999 15.3942 3.25 13.5564V8.17179L5.82911 5.7893L5.86415 5.7557C7.16347 4.5098 8.19323 3.52238 9.10742 2.85356ZM11.9955 15.75C11.3076 15.75 10.75 16.3096 10.75 17C10.75 17.6904 11.3076 18.25 11.9955 18.25C12.6777 18.25 13.25 17.6883 13.25 17C13.25 16.3117 12.6777 15.75 11.9955 15.75Z" fill="#22C55E" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1407 4.26594C9.33333 4.85661 8.38869 5.75951 7.03526 7.05727L2.69211 11.2218C2.29347 11.604 1.66045 11.5907 1.27821 11.1921C0.895967 10.7935 0.909258 10.1604 1.30789 9.77821L5.70301 5.56386C6.99217 4.32768 8.03207 3.33052 8.9598 2.6518C9.92464 1.94593 10.8777 1.5 12 1.5C13.1223 1.5 14.0754 1.94593 15.0402 2.6518C15.9679 3.33053 17.0078 4.32768 18.297 5.56387L22.6921 9.77821C23.0907 10.1604 23.104 10.7935 22.7218 11.1921C22.3396 11.5907 21.7065 11.604 21.3079 11.2218L16.9647 7.05728C15.6113 5.75951 14.6667 4.85662 13.8593 4.26594C13.0766 3.69332 12.5345 3.5 12 3.5C11.4655 3.5 10.9234 3.69332 10.1407 4.26594Z" fill="#22C55E" />
                </svg>
                : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.4" d="M12 17H12.009" stroke="#525252" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20 8.5V13.5C20 17.2712 20 19.1569 18.8284 20.3284C17.6569 21.5 15.7712 21.5 12 21.5C8.22876 21.5 6.34315 21.5 5.17157 20.3284C4 19.1569 4 17.2712 4 13.5V8.5" stroke="#525252" stroke-width="1.5" />
                    <path d="M22 10.5L17.6569 6.33548C14.9902 3.77849 13.6569 2.5 12 2.5C10.3431 2.5 9.00981 3.77849 6.34315 6.33548L2 10.5" stroke="#525252" stroke-width="1.5" stroke-linecap="round" />
                </svg>}
        </>
    )
}
export default HomeIcon;