
import { formatCurrency } from '../../../utils/helper';
import dashcircle from '../../../assets/dashcircle.svg'
import greendot from '../../../assets/greendot.svg'
export interface SpendingHeaderMessageProps {
  monthlyLimit: number;
  monthlySpend: number
}

export function SpendingHeaderMessage({ monthlyLimit,monthlySpend }: SpendingHeaderMessageProps) {
  const availableToSpend = monthlyLimit-monthlySpend;

  return (
    <div className="flex flex-col justify-end items-center gap-6 self-stretch">
      <div className="flex justify-center items-end gap-6 self-stretch">
        <div className="flex flex-col items-start gap-[var(--spacing-1, 4px)] flex-1">
          <div className="texth4">{formatCurrency(monthlySpend)} spend</div>
          <div className="intertextxs text-neutral-500">{formatCurrency(availableToSpend<0?0:availableToSpend)} available to spend</div>
        </div>
        <div className="flex w-[98px] h-[45px] flex-col justify-between items-end">
          <div className="flex items-center gap-1 self-stretch">
            <div className="intertextxs text-neutral-500">Actual Spend</div>
            <div><div><img src={greendot} sizes='4'></img></div></div>
          </div>
          <div className="flex items-center gap-1">
            <div className="intertextxs text-neutral-500">Daily Budget</div>
            <div><img src={dashcircle} sizes='4'></img></div>
          </div>
        </div>

      </div>
    </div>
  );
}
