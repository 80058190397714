import { Card, CardContent } from "../ui/Card";
import PlaidLinkComponent from "../plaid/PlaidLinkComponent";
import '../../App.css';
import Header from "../common/Header";
import FilteredAccounts from "./FilteredAccounts";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect, useState } from "react";
import { fetchAccountDetailAsync } from "../../store/thunks/TransactionThunk";
import { getUserAccountDetailsAsync } from "../../store/thunks/MasterThunk";
import RightArrow from '../../assets/RightArrow.svg';
import useDeepLinking from "../../hooks/useDeepLinking";
import { OnboardingState } from "../../store/slices/MasterSlice";
import HeaderWithBackIcon from "../common/HeaderWithBackIcon";
import loader from '../../assets/loader.gif';
import Spinner from "../common/Spinner";

export default function AccountDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const { accounts } = useSelector((state: RootState) => state.TransactionStore);
  const { userAccount } = useSelector((state: RootState) => state.MasterStore);
  //let userAccount= {"id":"d07f19b5-056c-404d-9c2b-eb7016570960","organization_id":2,"email":"geeta.pradan@gmail.com","email_verified":false,"spending_limit_adjusted":false,"first":"Geeta","middle":null,"last":"Pradan","mobile_phone":"+1(999)999-9999","job_title":null,"onboarding_state":"ONBOARDING_COMPLETE","terms_accepted_at":"2025-02-04T04:46:37.657443+00:00","user_role":"FINLEY_USER","external_unique_id":"128973427959478453843"}
  const navigate = useNavigate();
  const location = useLocation();

  useDeepLinking();
  useEffect(() => {

    if (location.pathname.includes('/callback')) {
      dispatch(getUserAccountDetailsAsync());
      dispatch(fetchAccountDetailAsync());
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUserAccountDetailsAsync());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchAccounts = async () => {
      await dispatch(fetchAccountDetailAsync());
    };

    fetchAccounts();
  }, [dispatch]);

  async function handleClick(): Promise<void> {
    const resultAction = await dispatch(getUserAccountDetailsAsync());
    if (getUserAccountDetailsAsync.fulfilled.match(resultAction)) {
      if (userAccount.onboarding_state === OnboardingState.ONBOARDING_COMPLETE) {
        navigate('/confirm-income')
      } else {
        navigate('/survey')
      }
    }
  }

  const handleBackClick = () => {
    navigate('/home')

  };

  return (
    <>
      <div className="lg:container lg:max-w-md h-screen flex flex-col bg-customBgColor">
        {userAccount.onboarding_state === OnboardingState.ONBOARDING_COMPLETE && <div className="pl-4"><HeaderWithBackIcon onBackClick={handleBackClick} /></div>}
        {userAccount.onboarding_state !== OnboardingState.ONBOARDING_COMPLETE && <div className="mt-5"> <Header /></div>}
        {/* Content area with flex-grow */}
        <div className="flex-grow overflow-y-auto p-3">
          <div className="flex mb-4 space-x-4">

            <div className="font-serif flex-col text-base font-normal leading-6 text-left">
              {userAccount.onboarding_state === OnboardingState.ONBOARDING_COMPLETE && <div className="font-serif text-xl font-bold leading-7 tracking-tightCustom">Create Your Smart Spending Plan</div>}
              {userAccount.onboarding_state !== OnboardingState.ONBOARDING_COMPLETE && <div className="font-serif text-xl font-bold leading-7 tracking-tightCustom">Connect Your Accounts.</div>}
              {userAccount.onboarding_state === OnboardingState.ONBOARDING_COMPLETE &&
                <>
                  <div className="text-darkBlue font-serif text-base font-normal leading-6 pt-3">I'll analyze your spending and show you exactly where your money goes. Then we'll find ways to save - all in just a few minutes.</div>
                  <div className="text-darkBlue font-serif text-base font-normal leading-6 pt-3">Should I include all these accounts in the analysis?</div>
                </>
              }
              {userAccount.onboarding_state !== OnboardingState.ONBOARDING_COMPLETE && <div className="text-darkBlue font-serif text-base font-normal leading-6 pt-3">To help you get the most out of your money, I'll need to securely connect to your financial accounts. The more I can see, the better I can help you reach your goals.</div>}
            </div>
          </div>

          {isLoading ? (<div className="flex justify-center">
            {/* <img src={loader}/> */}
            <Spinner/>
            </div>):(
          <div className="space-y-4">
            <Card
              className={`bg-white rounded-2xl w-full overflow-hidden transition-all duration-300 ${!accounts || (accounts && accounts.length === 0) ? 'h-[82px]' : 'min-h-[82px]'}`}
            >
              <CardContent className="pt-3">
                <div className="flex justify-between items-center">
                  <div className="text-center font-serif font-bold text-lg">Securely Connect My Accounts </div>
                  <PlaidLinkComponent setIsLoading={setIsLoading}  accountCountFromParent={accounts?.length}/>
                </div>
              </CardContent>
              {accounts && accounts.length > 0 && (
                <FilteredAccounts filterTypes={[]} isOnborded={userAccount.onboarding_state === OnboardingState.ONBOARDING_COMPLETE} />
                
              )}
            </Card>

            {/* Show this message if no accounts */}
            {accounts && accounts.length === 0 && (
              <div className="w-full text-darkBlue text-center font-inter text-[12px] font-medium leading-[20px]">
                At least one account required.
              </div>
            )}
          </div>)}

        </div>

        {/* Sticky footer button */}
        {userAccount.onboarding_state !== OnboardingState.ONBOARDING_COMPLETE ?
        <div className="sticky bottom-2 w-full bg-customBgColor  py-3 px-3">
          {accounts && accounts?.length > 0 && (
            <button
              onClick={handleClick}
              className="flex justify-center items-center cursor-pointer w-full bg-loginBg text3 text-white px-8 py-2 rounded-full"

            >
              <img
                src={RightArrow}
                className="size-4 mr-2"
                alt="Arrow"
              />
              {`Continue with ${accounts?.length} ${accounts.length === 1 ? "account" : "accounts"}`}
            </button>
          )}
        </div>
:
        <div className="sticky bottom-2 w-full bg-customBgColor py-3 px-3">
          {accounts && accounts?.length > 0 && (
            <button
              onClick={handleClick}
              className="flex justify-center items-center cursor-pointer w-full bg-loginBg text3 text-white px-8 py-2 rounded-full"

            >
              <img
                src={RightArrow}
                className="size-4 mr-2"
                alt="Arrow"
              />
              Analyze My Spending
            </button>
          )}
        </div>
}
      </div>
    </>
  );
}
