import React, { useEffect, useState } from 'react';
import AppLogo from "../../../assets/AppLogo";
import { Button } from '@headlessui/react';
import DrawerComponent from '../../common/Drawer';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchAnalyzeDataAsync } from "../../../store/thunks/TransactionThunk";
import { ExpenseList } from './ExpenseList';
import { SpendingBar } from './SpendingBar';
import { STATUS } from '../../../types/CommonType';
import { ExpenseMessage } from './ExpenseMessage ';
import { CategoryComponent } from './CategoryComponent';
import { categoriesImage } from "../../common/categories";
import InfoIcon from '../../../assets/InfoIcon';
const title = {
  Essential: 'Essentials',
  Other: "Other"

};


export function OverView() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState<{ title: string; content: React.ReactNode } | null>(null);
  const spendingState = useSelector((state: RootState) => state.TransactionStore.spendingState);
  const colors = ['bg-green-400', 'bg-green-500', 'bg-green-600', 'bg-green-700'];
  const [spendingAdjustments, setSpendingAdjustments] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    const fetchAnalyseData = async () => {
      await dispatch(fetchAnalyzeDataAsync());
    };
    fetchAnalyseData();
  }, [dispatch]);

  const filterByCategoryType = (type: string) => {
    const filtered = spendingState.spendingBreakDown?.filter((item) => item.categoryType === type);
    if (!filtered || filtered.length < 1) return null;

    // Sort the filtered data by amount in descending order and pick the top 4
    const sortedData = filtered
      .sort((a, b) => parseFloat(b?.amount ?? 0) - parseFloat(a?.amount ?? 0))
      .slice(0, 4);

    // Calculate the totalCategoryExpense for the top 4 items
    const totalCategoryExpense = sortedData.reduce((sum, item) => sum + parseFloat(item?.amount ?? 0), 0);

    const expensesData = sortedData.map((item, index) => ({
      category: item.finleyCategoryName,
      finleyCategory: item.finleyCategory,
      type: item.categoryType,
      amount: item?.amount ?? 0,
      color: colors[index] || 'bg-green-800',
    }));

    return { expensesData, totalCategoryExpense };
  };

  const generateDrawerContent = (type: string) => {
    const filtered = spendingState.spendingBreakDown?.filter((item) => item.categoryType === type);
    return filtered?.map((element, index) => (
      <CategoryComponent key={index} categoryName={element.finleyCategoryName} amount={element.amount} imageName={element.finleyCategory} />
    )

    )
  }
  // const handleOpenDrawer = (title: string, content: React.ReactNode) => {
  //   setDrawerContent({ title, content });
  //   setIsDrawerOpen(true);
  // };

  const handleOpenDrawer = (title: string, type: string) => {
    const content = generateDrawerContent(type); // Get the content here
    setDrawerContent({ title, content });
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerContent(null);
  };

  const { expensesData: essentialExpenses, totalCategoryExpense: essentialTotalExpense } =
    filterByCategoryType('ESSENTIAL') || { expensesData: [], totalCategoryExpense: 0 };

  const { expensesData: otherExpenses, totalCategoryExpense: otherTotalExpense } =
    filterByCategoryType('OTHER') || { expensesData: [], totalCategoryExpense: 0 };


  const closeModal = () => {
    setShowEditModal(false);
    setSelectedCategory(null);
  };
  const getIcon = (category: any) => {
    const match = categoriesImage.find(
      (item) => item.categoryName.toUpperCase() === category
    );
    return match ? match.icon : "❓";
  };
  const handleNumberClick = (num: any) => {
    setAmount((prev) => Number(`${prev}${num}`));
  };

  const handleBackspace = () => {
    setAmount((prev) => Math.floor(prev / 10));
  };

  const handleSave = () => {


    const updatedExpense = {
      ...selectedCategory,
      amount, // Updated amount
    };

    if (selectedCategory.type === "ESSENTIAL") {
      essentialExpenses.forEach((item: any) => {
        if (item.finleyCategory === selectedCategory.finleyCategory) {
          item.amount = amount; // Update directly
        }
      });
    } else if (selectedCategory.type === "OTHER") {
      otherExpenses.forEach((item: any) => {
        if (item.finleyCategory === selectedCategory.finleyCategory) {
          item.amount = amount; // Update directly
        }
      });
    }

    closeModal();
  };

  const handleCategoryClick = (category: any) => {
    setSelectedCategory(category);
    setAmount(parseFloat(category.amount));
    setShowEditModal(true);
  };

  return (
    <>
      <div className="flex flex-col h-screen">


        {/* header start */}
        <header className="sticky top- z-20">
          <div className="flex justify-center items-center mx-auto pb-3 pt-4 sticky top-0">
            <AppLogo />
            <div className="ml-2 carouselTitle">
              <h4>Finley</h4>
            </div>
          </div>
        </header>
        {/* header end */}
        {/* content start */}
        <main className="flex-1 overflow-auto">
          <div className="p-3">
            <div className="carouselTitle">
              Here's what I learned from your past 6 months of spending:
            </div>

            <div className="flex mt-2 p-5 flex-col flex-grow items-start self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white">
              <div className="text3 mb-2">You're spending about</div>
              {
                spendingState.status === STATUS.SUCCESS ? (
                  <><div className="texth2">{`$${spendingState.monthlyAvgSpending}/month`}</div>
                    <div className="text3 mt-2">
                      <ExpenseMessage overSpendingAmount={
                        (spendingState?.monthlyAvgIncome ?? 0) - (spendingState?.monthlyAvgSpending ?? 0)
                      } />
                    </div></>
                ) : (
                  <div className="text3">Loading...</div>
                )
              }

            </div>

            <div className="carouselTitle py-4">Your Monthly Spending Breakdown:</div>
            <div className="flex  p-5 flex-col flex-grow items-start self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white">
              <div className="textlarge700 ">{title.Essential}</div>
              <div className='text3 pb-3' >The basics that keep your life running - like housing, utilities, groceries, and transport.</div>
              <SpendingBar expenses={essentialExpenses} totalExpense={essentialTotalExpense} />
            
              <ExpenseList expenses={essentialExpenses} handleCategoryClick={handleCategoryClick} />
              <div className="pt-3">
                <Button
                  className="h-10 w-[110px] rounded-full bg-neutral-100 text3"
                  onClick={() => handleOpenDrawer(title.Essential, 'ESSENTIAL')}
                >
                  See All
                </Button>
              </div>
            </div>
            {/* Essential Spending Section */}
            <div className="flex mt-3 p-5 flex-col flex-grow items-start self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white">


              <div className="textlarge700">{title.Other}</div>
              <div className='text3 pb-3' >Spending that adds enjoyment to your life - like dining out, shopping, and entertainment.</div>
              <SpendingBar expenses={otherExpenses} totalExpense={otherTotalExpense} />
          
              <ExpenseList expenses={otherExpenses} handleCategoryClick={handleCategoryClick} />
              <div className="pt-3">
                <Button
                  className="h-10 w-[110px] rounded-full bg-neutral-100 text3"
                  onClick={() => handleOpenDrawer(title.Other, 'OTHER')}
                >
                  See All
                </Button>
              </div>
            </div>
          </div>
        </main>
        {/* content end */}
        <footer>
          <div className="sticky bg-white bottom-0 left-0 right-0 h-[80px] pt-3 w-full pb-[25px] px-[25px] border border-t-[1px] border-neutral-200">
            <button className="px-4 py-2 text-white bg-neutral-900 rounded-full text3 w-full" onClick={() => navigate('/spend-limit-overview')}>
            Show Me Ways to Save
            </button>
          </div>
        </footer>


 </div>

      {/* Drawer Component */}
      {isDrawerOpen && drawerContent && (
        <DrawerComponent
          isOpen={isDrawerOpen}
          onClose={handleCloseDrawer}
          title={drawerContent?.title || ''}
          content={drawerContent?.content || ''}
        />
      )}


      {/* Slide-up Modal */}
      {showEditModal && selectedCategory && (
        <div
          className="fixed inset-0 bg-black bg-opacity-30 flex items-end"
          onClick={closeModal}
        >
          <div
            className="modal-content slide-up bg-white w-full h-[90%] rounded-t-2xl p-5"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="w-full flex justify-center">
              <div className="bg-topIcon w-9 h-[3px] rounded-full"></div>
            </div>
            <div className="w-full px-3 pt-10">
              <div className="flex">
                <div className="flex items-center text-darkBlue text6 justify-center mx-auto pr-6">
                  {getIcon(selectedCategory.finleyCategory)}
                  <span className="ml-3 text3">{selectedCategory.category}</span>
                </div>
              </div>
              <div className="flex justify-center">
                <div
                  className="flex items-center justify-center"
                  style={{
                    width: "177.563px",
                    height: "178px",
                  }}
                >
                  <div className="flex flex-col text-center">
                    <span className="text-green intertextxs">Cutting back by</span>
                    <span className="text-green mt-2 mb-3 texth1">${amount}</span>
                    <span className="text-green intertextxs">monthly</span>
                  </div>
                </div>
              </div>
              <div className="bg-infoBg rounded border border-borderGrey flex h-[80px] w-full">
                <div className="pt-3 pl-1">
                  <InfoIcon />
                </div>
                <div className="text4 text-neutral500 ml-1 mt-2">
                  I recommend setting your spending limit for {selectedCategory.category} to ${selectedCategory.amount}.
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-10 mt-10">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, ".", 0, "<"].map((key, index) => (
                <button
                  key={index}
                  className="text6"
                  onClick={() =>
                    key === "<"
                      ? handleBackspace()
                      : handleNumberClick(Number(key))
                  }
                >
                  {key}
                </button>
              ))}
            </div>
            <button
              className="mt-14 bg-black text-white h-10 w-full rounded-full text-lg font-semibold"
              onClick={handleSave}
            >
              <div className="intertextmedium">Save Spending Limits</div>
            </button>
          </div>
        </div>
      )}
    </>
  );
};



