
import { useEffect, useState } from 'react';
import FullRightDrawer from '../../common/FullRightDrawer';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDailySpendsAsync } from '../../../store/thunks/SpendingThunk';
import { formatCurrency } from '../../../utils/helper';
import lifebuoy from '../../../assets/life-buoy.svg';
import building from '../../../assets/building-2.svg';
import signout from '../../../assets/arrow-left-circle.svg';
import user from '../../../assets/user.svg';
import SignOutModal from './SignOutModal';
import { useNavigate } from 'react-router';
import { resetForgotPasswordStatus, resetLoginStatus } from '../../../store/slices/AuthSlice';
import { Settings } from './Settings';
import FinancialAccountConnect from './FinancialAccountConnect';
import ActionModal from '../../common/ActionModal';
import { fetchAccountSummaryByTypeAsync } from '../../../store/thunks/TransactionThunk';
import { resetLinkToken } from '../../../store/slices/TransactionSlice';

type Props = {};

export default function AccountHome({ }: Props) {
    const dispatch = useDispatch<AppDispatch>();
    const [isMainDrawerOpen, setIsMainDrawerOpen] = useState(false); // Main drawer state
    const [isOverlayDrawerOpen, setIsOverlayDrawerOpen] = useState(false); // Overlay drawer state
    const [activeSection, setActiveSection] = useState<string | null>(null); // Active section
    const [showSignOutModal, setShowSignOutModal] = useState(false); // Modal state
    const userName = useSelector((state: RootState) => state.MasterStore.userAccount);
    const spendingState = useSelector((state: RootState) => state.spendingStore.fetchDailySpendsResponse);
    const accountSummaryTypeList = useSelector((state: RootState) => state.TransactionStore.accountSummaryTypeList)
    const navigate = useNavigate();
    const openMainDrawer = () => setIsMainDrawerOpen(true);
    const closeMainDrawer = () => setIsMainDrawerOpen(false);
    const openOverlayDrawer = () => setIsOverlayDrawerOpen(true);
    const closeOverlayDrawer = () => setIsOverlayDrawerOpen(false);

    useEffect(() => {
        dispatch(fetchAccountSummaryByTypeAsync());
    }, []);

    // Handle sign-out action
    const handleSignOut = () => {

        localStorage.removeItem('accessToken');
        dispatch(resetLoginStatus());
        navigate('/')
        setShowSignOutModal(false); // Close modal
    };

    // Handle cancel action (Close the modal)
    const handleCancelSignOut = () => {
        setShowSignOutModal(false);
    };

    const renderSignOutModal = () => {

        return (
            // <SignOutModal
            //     isOpen={showSignOutModal}
            //     onClose={handleCancelSignOut}
            //     onSignOut={handleSignOut}
            // />
            <ActionModal
                isOpen={showSignOutModal}
                onClose={handleCancelSignOut}
                onAction={handleSignOut}
                message="Are you sure you want to sign out?"
                cancelText="Cancel"
                actionText="Sign Out"
            />
        );
    };

    useEffect(() => {
        const fetchDailySpends = async () => {
            await dispatch(fetchDailySpendsAsync());
        };
        fetchDailySpends();
    }, [dispatch]);

    const handleBack = () => {
        if (activeSection=='Connected Accounts')
        {
            dispatch(resetLinkToken());
        }
        setActiveSection(null); // Reset active section to show ManageSettings content
        closeOverlayDrawer(); // Close overlay drawer when going back
    };

    // Render Account Details content
    const renderAccountDetails = () => (

        <Settings activeSection='Account Details' />

    );

    // Render Support content
    const renderSupport = () => (
        <div className='flex flex-col items-start gap-6 pt-6 px-4'>
            {/* Add Support content here */}
        </div>
    );

    const renderFinancialAccounts = () => (
        <div className="">
            <FinancialAccountConnect />
        </div>
    );

    const renderAccountSummary = () => {
        return (
            accountSummaryTypeList.map(item => {
                return (
                    <div className='flex justify-between items-start self-stretch' key={item.type}>
                        <div className='intertextxs text-neutral-500'>{item.type}</div>
                        <div className='intertextxs text-neutral-500'>{formatCurrency(item.availableBalanceAmount)}</div>
                    </div>
                );
            })

        )
    };
    // Render Manage Settings content with the provided HTML
    const renderManageSettings = () => (
        <div className='flex flex-col items-start gap-6 pt-6 pb-5'>
            <div className="flex flex-col items-center gap-3 self-stretch">
                <div className=''>
                    <img src={user} alt="user" />
                </div>
                <div className="textlarge700">{userName.first} {userName.last}</div>
                <div className='texth2'>
                    {formatCurrency(spendingState?.remainingLimit != null && spendingState.remainingLimit < 0
                        ? 0
                        : spendingState?.remainingLimit || 0)}
                </div>
                <div className='intertextxs text-neutral-500'>of {formatCurrency(spendingState?.monthlyLimit || 0)} monthly limits</div>
            </div>

            <div className="flex flex-col gap-3 self-stretch">
                <div className='textlarge700'>Financial Overview</div>
                {renderAccountSummary()}

            </div>

            <div className="flex flex-col  gap-3 self-stretch">
                <div className='textlarge700'>Manage your settings</div>
                {/* Support Button */}
                <div className="flex justify-center items-center  self-stretch" onClick={() => {
                    setActiveSection('Support');
                    openOverlayDrawer(); // Open the overlay drawer here
                }}>
                    <div className="flex p-[25px] items-center gap-4 flex-1 rounded-2xl border border-neutral-200 bg-white">
                        <div><img src={lifebuoy} alt="support" /></div>
                        <div className="flex flex-col justify-center items-start gap-1 flex-1">
                            <div className='intertextsmall'>Support</div>
                            <div className='intertextxs text-neutral-500'>Get in touch with customer support.</div>
                        </div>
                    </div>
                </div>

                {/* Account Details Button */}
                <div className="flex justify-center items-center gap-2 self-stretch" onClick={() => {
                    setActiveSection('Account Details');
                    openOverlayDrawer(); // Open the overlay drawer here
                }}>
                    <div className="flex p-[25px] items-center gap-4 flex-1 rounded-2xl border border-neutral-200 bg-white">
                        <div><img src={user} alt="account" /></div>
                        <div className="flex flex-col justify-center items-start gap-1 flex-1">
                            <div className='intertextsmall'>Account Details</div>
                            <div className='intertextxs text-neutral-500'>Manage your login information.</div>
                        </div>
                    </div>
                </div>

                {/* Financial details */}
                <div className="flex justify-center items-center gap-2 self-stretch" onClick={() => {
                    setActiveSection('Connected Accounts');
                    openOverlayDrawer(); // Open the overlay drawer here
                }}>
                    <div className="flex p-[25px] items-center gap-4 flex-1 rounded-2xl border border-neutral-200 bg-white">
                        <div><img src={building} alt="account" /></div>
                        <div className="flex flex-col justify-center items-start gap-1 flex-1">
                            <div className='intertextsmall'>Financial Accounts</div>
                            <div className='intertextxs text-neutral-500'>Manage your connected accounts.</div>
                        </div>
                    </div>
                </div>

                {/* Sign Out Button */}
                <div className="flex justify-center items-center gap-2 self-stretch" onClick={() => setShowSignOutModal(true)}>
                    <div className="flex p-[25px] items-center gap-4 flex-1 rounded-2xl border border-neutral-200 bg-white">
                        <div><img src={signout} alt="signout" /></div>
                        <div className="flex flex-col justify-center items-start gap-1 flex-1">
                            <div className='intertextsmall'>Sign Out</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // The content to be displayed in the drawer based on the active section
    const drawerContent = () => {
        if (activeSection === 'Account Details') {
            return renderAccountDetails();
        } else if (activeSection === 'Support') {
            return renderSupport();
        } else if (activeSection === 'Connected Accounts') {
            return renderFinancialAccounts();
        } else {
            return renderManageSettings(); // Default is ManageSettings
        }
    };

    return (
        <div>
            {/* Main Logo with onClick to open the main drawer */}
            <img
                className="size-7 cursor-pointer"
                src={user}
                alt="user"
                onClick={openMainDrawer}

            />

            {/* Main Drawer (background) */}
            <FullRightDrawer
                isOpen={isMainDrawerOpen}
                onClose={closeMainDrawer}
                title={<div className="carouselTitle">Account</div>}
                content={renderManageSettings()}
                closeIcon={true}
            />

            {/* Conditionally Render Overlay Drawer based on Active Section */}
            {activeSection && (
                <FullRightDrawer
                    isOpen={isOverlayDrawerOpen}
                    onClose={closeOverlayDrawer}
                    title={<div className="carouselTitle">{activeSection || "Account"}</div>}
                    content={drawerContent()}
                    closeIcon={false}
                    onBack={handleBack}
                    width="w-full"
                />
            )}

            {/* Render SignOut Modal */}
            {showSignOutModal && renderSignOutModal()}
        </div>
    );
}
