import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDailySpends, editSpendingLimitService, spendingAdjustment, fetchCategorySpends, fetchCategoryLimit, saveSpendingLimitFlag } from "../../services/SpendingService";


export const spendingAdjustmentsAsync = createAsyncThunk(
  "user/spendAdjustments",
  async ( thunkAPI) => {
    const response = await spendingAdjustment();
    return response;
  }
);


export const editSpendingLimitAsync = createAsyncThunk(
  "user/editSpends",
  async (data: any, thunkAPI) => {
    const response = await editSpendingLimitService(data);
    return response;
  }
);


export const fetchDailySpendsAsync = createAsyncThunk(
  "user/dailySpends",
  async ( thunkAPI) => {
    const response = await fetchDailySpends();
    return response;
  }
);

export const fetchCategorySpendsAsync = createAsyncThunk(
  "user/fetchCategorySpent",
  async (thunkAPI) => {
    const response = await fetchCategorySpends();  
    return  response;
  }
);


export const fetchCategoryLimitAsync = createAsyncThunk(
  "user/fetchCategoryLimit",
  async (inley_category: string, thunkAPI) => {
    try {
      const response = await fetchCategoryLimit(inley_category);
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const saveSpendingLimitFlagAsync = createAsyncThunk(
  "user/saveSpendingLimitFlag",
  async ( _,thunkAPI) => {
    try {
      const response = await saveSpendingLimitFlag();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);


