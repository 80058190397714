import React from "react";
import { formatCurrency, roundUp } from "../../../utils/helper";

const Ellipse: React.FC<{ amount: number; title: string }> = ({ amount, title }) => {
  const isNegative = (amount) < 0;

  return (
    <div
      className={`flex items-center justify-center rounded-full ${
        isNegative ? "bg-rose-50" : "bg-green-50"
      }`}
      style={{
        width: "177.563px",
        height: "178px",
      }}
    >
      <div className="flex flex-col items-center">
        <span className={`intertextxs ${isNegative ? "text-rose-500" : "text-green-600"}`}>
          {title}
        </span>
        <span className={`texth1medium ${isNegative ? "text-rose-500" : "text-green-600"}`}>
          {formatCurrency(Math.abs( roundUp(amount,2)))}
        </span>
        <span className={`intertextxs ${isNegative ? "text-rose-500" : "text-green-600"}`}>
          monthly
        </span>
      </div>
    </div>
  );
};

export default Ellipse;
