export const ExpenseMessage = ({ overSpendingAmount }: { overSpendingAmount: number }) => {
  const isOverspending = overSpendingAmount < 0;
  const displayAmount = Math.abs(overSpendingAmount).toFixed(2);

  return (
    <div>
      {isOverspending ? (
        <div>
          Which is ${displayAmount} more than your monthly income. Don't worry - I'll help you create a balanced plan that works for your lifestyle.
        </div>
      ) : (
        <div>
          Which is ${displayAmount} less than your income - great work! I can help you find even more ways to boost your savings.
        </div>
      )}
    </div>
  );
};
