
import Kimberly from '../../../assets/Kimberly.svg'
import Danielle from '../../../assets/Danielle.svg'
import Melissa from '../../../assets/Melissa.svg'
import Saul from '../../../assets/Saul.svg'
import Shawn from '../../../assets/Shawn.svg'
import { AppDispatch } from "../../../store/store";
import { useEffect } from "react";
import { getUserAccountDetailsAsync } from "../../../store/thunks/MasterThunk";
import { useDispatch } from "react-redux";
import coachesData from "./CoachInfo.json";
import { useNavigate } from "react-router";
import GreetingHeader from "../../common/GreetingHeader";
export interface CoachingPageHomeProps {
    selectTab: (tabName: string) => void;
}


const CoachingPageHome = ({ selectTab }: CoachingPageHomeProps) => {
    const dispatch = useDispatch<AppDispatch>();

    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getUserAccountDetailsAsync());
        };
        fetchData();
    }, [dispatch]);
    const imageMap: { [key: string]: string } = {
        "Kimberly": Kimberly,
        "Danielle":Danielle,
        "Melissa":Melissa,
        "Saul":Saul,
        "Shawn":Shawn

    };

    return (
        <div >
            {/* <GreetingHeader/> */}
            <div className="bg-white  mx-2 px-4 fixed inset-0 top-20 flex flex-col  pb-6 mb-10 rounded-2xl">
                <div className="textlarge700 pt-4">
                    Find an Accredited Financial Counselor® that’s right for you. Book an always free, 30-minute session now.
                </div>

                <div className="flex-grow  mt-4 mb-7 overflow-y-scroll hide-scrollbar">
                    {coachesData.map((coach, index) => (
                        <div onClick={()=>{navigate(`/ScheduleMeeting/${coach.CoachId}`)}} key={index} className=" mb-6 border bottom-borderGrey h-[138px] rounded-2xl">
                            <div className="p-3 flex items-center">
                                <img height="50px" width="50px"   src={imageMap[coach.imgSrc]} alt="coach" />
                                <div className="coach ml-3">{coach.name}</div>
                            </div>
                            <div className="flex gap-3 px-5 overflow-x-auto hide-scrollbar">
                                {coach.tags.map((tag, tagIndex) => (
                                    <div
                                        key={tagIndex}
                                        className="flex w-fit items-center px-3 py-2 rounded-lg border border-neutral-300"
                                    >
                                        <div className="text-primary text-nowrap mr-2">{tag}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CoachingPageHome;