
import { useNavigate } from 'react-router';
import HomeIcon from '../../assets/HomeIcon';
import FinleyAIIcon from '../../assets/FinleyAIIcon';
import SpendingIcon from '../../assets/SpendingIcon';
import MeetCoachIcon from '../../assets/MeetCoachIcon';
type TabName = 'Home' | 'Spending' | 'Meet Coach' | 'Finley AI';
interface FooterProps {
    selectTab: (tabName: TabName | any) => void;
    activeTab: any
}
function Footer({ selectTab, activeTab }: FooterProps) {
    const navigate = useNavigate();
    const handleTabClick = (tabName: TabName, route?: string): void => {

        selectTab(tabName);
        // setActiveTab(tabName);
        if (route) {
            navigate(route);
        }
    };

    const activeStyles = '  text-footerGreen mt-[2px] ';
    const inactiveStyles = 'text-neutral-400 mt-[2px]';
    const color = {
        active: '#22C55E',
        inactive: '#525252'
    }
    return (

        <div className="sticky  bg-white bottom-1 left-0 right-0 h-[95px] ">
            <div className="grid grid-cols-4 w-full max-w-[800px] mx-auto ">

                <div
                    className={`flex relative flex-col px-4 pt-[7px] pb-[34px] items-center ${activeTab === 'Home' ? activeStyles : inactiveStyles}`}
                    onClick={() => handleTabClick('Home')}
                >
                    {activeTab == 'Home' && <div className='bg-footerGreen absolute rounded-b-[100px] top-[-2px]  h-[2px]  px-2 w-[85%]'></div>}


                    <HomeIcon color={`${activeTab === 'Home' ? color.active : color.inactive}`}></HomeIcon>

                    <div className=" mt-1 intertextxs">Home</div>
                </div>
                <div
                    className={`flex relative flex-col px-4 pt-[7px] pb-[34px] items-center ${activeTab === 'Finley AI' ? activeStyles : inactiveStyles}`}
                    onClick={() => handleTabClick('Finley AI')}
                >
                    {activeTab == 'Finley AI' && <div className='bg-footerGreen absolute rounded-b-[100px] top-[-2px] h-[2px]  px-2 w-[90%]'></div>}

                    <FinleyAIIcon color={`${activeTab === 'Finley AI' ? color.active : color.inactive}`} />

                    <div className="intertextxs mt-1">Finley AI</div>
                </div>
                <div
                    className={`flex relative flex-col px-4 pt-[7px] pb-[34px] items-center ${activeTab === 'Spending' ? activeStyles : inactiveStyles}`}
                    onClick={() => handleTabClick('Spending')}
                >
                    {activeTab == 'Spending' && <div className='bg-footerGreen absolute rounded-b-[100px] top-[-2px] h-[2px]  px-2 w-[90%]'></div>}

                    <SpendingIcon color={`${activeTab === 'Spending' ? color.active : color.inactive}`} />
                    <div className="mt-1 intertextxs">Spending</div>
                </div>
                <div
                    className={`flex relative flex-col flex-nowrap  pt-[7px] pb-[34px] items-center ${activeTab === 'Meet Coach' ? activeStyles : inactiveStyles}`}
                    onClick={() => handleTabClick('Meet Coach')}
                >
                    {activeTab == 'Meet Coach' && <div className='bg-footerGreen absolute rounded-b-[100px] top-[-2px] h-[2px]  px-2 w-[90%]'></div>}

                    <MeetCoachIcon color={`${activeTab === 'Meet Coach' ? color.active : color.inactive}`} />

                    <div className="intertextxs mt-1  text-nowrap">Meet Coach</div>
                </div>



            </div>
        </div>


    );
}

export default Footer;
