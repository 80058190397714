import { formatCurrency, roundUp } from "../../../utils/helper";

export const SpendingLimitTitleMessage = ({
  overSpendingAmount,
  recommendedAmount,
}: {
  overSpendingAmount: number;
  recommendedAmount: number;
}) => {

  const displayAmount = recommendedAmount < 0
  ? formatCurrency(Math.abs(roundUp(recommendedAmount, 2)))  // Correctly close the parentheses for Math.abs and formatCurrency
  : formatCurrency(roundUp(recommendedAmount, 2));           // Correctly call formatCurrency


  // If recommendedAmount is negative, return the specific message
  if (recommendedAmount < 0) {
    return (
      <div>
        Your current spending limits will lead to overspending.
      </div>
    );
  }

  // Only check for overspending or underspending if recommendedAmount is positive
  if (recommendedAmount > 0) {
    const isOverspending = overSpendingAmount < 0;

    return (
      <div>
        {isOverspending ? (
          <div>
            I've found a few ways to adjust your spending that could save {displayAmount} each month.
          </div>
        ) : (
          <div>
            I've found a few ways to adjust your spending that could free up {displayAmount} each month.
          </div>
        )}
      </div>
    );
  }

  return null; // This will never be reached, but just a fallback
};
