import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GreetingHeader from '../../common/GreetingHeader';
import MonthlySpendLineChart from '../Charts/MonthlySpendLineChart';
import UpcomingBills from '../../common/UpcomingBills';
import CategorySpendingChart from '../Charts/CategorySpendingChart';
import Transactions from '../../common/Transactions';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchCategorySpendsAsync } from '../../../store/thunks/SpendingThunk';
import DrawerFullHeight from '../../common/DrawerFullHeight';
import { FinleyCategorySpentData } from '../../../store/slices/SpendingsSlice';
import { fetchSpendingCategoryTransactionAsync } from '../../../store/thunks/TransactionThunk';
import SpendCategoryData from './SpendCategoryData';

export interface SpendingHomeProps {
  selectTab: (tabName: string) => void;
}

const SpendingHome: React.FC<SpendingHomeProps> = ({ selectTab }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [allTransactionFlag, setAllTransactionFlag] = useState(false);
  const [categorySpending, setCategorySpending] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<FinleyCategorySpentData>();

  const categorySpentResponseState = useSelector(
    (state: RootState) => state.spendingStore.fetchCategorySpentResponse
  );

 
  const spendCategoryData = () => { 
    return (
      <SpendCategoryData
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    );
  };

  useEffect(() => {
    dispatch(fetchCategorySpendsAsync());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSpendingCategoryTransactionAsync(selectedCategory?.finleyCategory));
  }, [dispatch, selectedCategory]);


  const handleTransactionFlagChange = useCallback((flag: boolean) => {
    setAllTransactionFlag(flag);
  }, []);

  return (
    <div className="">
      {/* <GreetingHeader /> */}
      <div className={`flex-1 overflow-y-auto  px-3`}>
        <div className='flex flex-col rounded-2xl border border-neutral-200 bg-white p-[25px] mb-3'>
          <MonthlySpendLineChart />
        </div>
        <div className="rounded-2xl border border-neutral-200 bg-white p-[25px]  text2">
          Spending by category
          <div className="flex flex-wrap justify-centre ">
            {categorySpentResponseState?.map((category) => (
              <div onClick={() => {
                setCategorySpending(true)
                setSelectedCategory(category)
              }} className="pt-[25px]" style={{ width: '25%' }} key={category.finleyCategory}>
                <CategorySpendingChart
                  finleyCategory={category.finleyCategory}
                  spendPercentage={category.spendPercentage}
                  spendStatus={category.spendStatus}
                  spendRemaining={category.spendRemaining || 0}
                />
              </div>
            ))}
          </div>

          <div />

        </div>
        <UpcomingBills />
        {/* Only trigger a re-render on the Transactions component */}
        <div>
          <Transactions
            allTransactionFlag={allTransactionFlag}
            setAllTransactionFlag={handleTransactionFlagChange} />
        </div>
      </div>
      <div>
        <DrawerFullHeight isOpen={categorySpending} onClose={function (): void {
          setCategorySpending(false);
        }} title={selectedCategory?.finleyCategoryName} border={true} content={spendCategoryData()} onBack={function (): void {
          setCategorySpending(false);
        }} />
      </div>
      <div>
      
      </div>
    </div>
  );
};

export default SpendingHome;
