
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserAccountDetails } from "../../services/MasterService";
// export const getUserAccountDetailsAsync = createAsyncThunk(
//     "user/getUserAccountDetails",
//     async () => {
//       const response: any = await getUserAccountDetails();
//       return response;
//     }
//   );
  

export const getUserAccountDetailsAsync = createAsyncThunk(
  "user/getUserAccountDetailsAsync",
  async (_, thunkAPI) => {
    try {
      const response = await getUserAccountDetails();
      return response; 
    } catch (error) {
      let errorMessage = "An unknown error occurred";

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
)