import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect } from "react";
import { fetchAllTransactionsAsync } from "../../store/thunks/TransactionThunk";
import BackIcon from "../../assets/BackIcon";
import { formatCurrency } from "../../utils/helper";
import { format } from 'date-fns';
import DrawerFullHeight from "./DrawerFullHeight";
interface TransactionsProps {
    allTransactionFlag: boolean;
    setAllTransactionFlag: (flag: boolean) => void;
}
const Transactions: React.FC<TransactionsProps> = ({ allTransactionFlag, setAllTransactionFlag }) => {
    const dispatch = useDispatch<AppDispatch>();

    const transactionData = useSelector((state: RootState) => state.TransactionStore).transactions;

    useEffect(() => {
        const fetchTransactionsData = async () => {
            await dispatch(fetchAllTransactionsAsync());
        };
        fetchTransactionsData();
    }, [dispatch]);

    const renderTransactions = (limit?: number) => {
        if (!transactionData || transactionData.length === 0) {
            return <div>No transactions available</div>;
        }
        const displayedTransactions = limit
            ? transactionData.slice(0, limit)
            : transactionData;

        return (
            <>
                {displayedTransactions.map((x, index) => (
                    <div
                        key={index}
                        className="w-full flex p-3 justify-between items-center self-stretch border border-borderGrey rounded-2xl mb-3 mt-4"
                    >
                        {/* Merchant Details */}
                        <div className="flex flex-col items-start">
                            <div className="text3">{x.merchant_name || "Unknown Merchant"}</div>
                            <div className="intertextxs text-neutral-500">{x.finley_category_name || "N/A"}</div>
                        </div>

                        {/* Transaction Details */}
                        <div className="flex flex-col items-end">
                            <div
                                className={`text3 ${x.amount < 0 ? "text-[#22C55E]" : ""
                                    }`}
                            >
                                {x.amount < 0 ? formatCurrency(Math.abs(x.amount)) : formatCurrency(x.amount)}
                            </div>
                            <div className="intertextxs text-neutral-500">{format(x.transaction_date,'MM-dd-yyyy')}</div>
                        </div>
                    </div>
                ))}
            </>
        );
    };


    const seeAllTransaction = (
        <div className="">
            <div className="bg-white mt-3 ">
                <div className="flex pt-3 ">
                    <div onClick={() => setAllTransactionFlag(false)}> <BackIcon /></div>
                    <div className="carouselTitle mx-auto">All Transactions</div>
                </div>
                <hr className="mt-3"></hr>
                <div className="overflow-scroll h-screen px-3 py-3">
                    {renderTransactions()}
                </div>
            </div>
        </div>
    )

    return (
        <>
            <div className="flex mt-3 p-[25px] flex-col flex-grow items-start self-stretch rounded-2xl border-[1px] border-solid border-neutral-200 bg-white mb-3">
                <div className="textlarge700 ">Transactions</div>
                {renderTransactions(5)}
                <div className="w-full my-6">
                    <button
                        onClick={() => setAllTransactionFlag(true)}
                        className="flex w-full items-center justify-center px-4 py-2 bg-customGray text-loginBg rounded-full space-x-1 text3"
                    >
                        <span>See All</span>
                    </button>
                </div>
            </div>


                <DrawerFullHeight isOpen={allTransactionFlag} onClose={function (): void {
                    setAllTransactionFlag(false);
                }} title={undefined} content={seeAllTransaction} />
       
        </>
    )
}
export default Transactions;